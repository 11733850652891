import MarkHospital from '../../../static/images/mark-hospital-small.svg'
import MarkSchool from '../../../static/images/mark-school-small.svg'
import MarkShopping from '../../../static/images/mark-shopping-small.svg'

import MarkTheGardens from '../../../static/images/markers/the-gardens/mark-the-gardens.svg'


import MarkPalmJebelAli from '../../../static/images/markers/palm-jebel-ali/mark-palm-jebel-ali.svg'
import PalmJebelAliPreview1 from '../../../static/images/markers/palm-jebel-ali/preview1.jpg'
import PalmJebelAliPreview2 from '../../../static/images/markers/palm-jebel-ali/preview2.jpg'
import PalmJebelAliPreview3 from '../../../static/images/markers/palm-jebel-ali/preview3.jpg'
import PalmJebelAliPreview4 from '../../../static/images/markers/palm-jebel-ali/preview4.jpg'
import PalmJebelAliPreview5 from '../../../static/images/markers/palm-jebel-ali/preview5.jpg'
import PalmJebelAliPreview6 from '../../../static/images/markers/palm-jebel-ali/preview6.jpg'
import PalmJebelAliPreview7 from '../../../static/images/markers/palm-jebel-ali/preview7.jpg'
import PalmJebelAliPreview8 from '../../../static/images/markers/palm-jebel-ali/preview8.jpg'
import PalmJebelAliPreview9 from '../../../static/images/markers/palm-jebel-ali/preview9.jpg'

import PalmJebelAliGuidelines1 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_01.jpg"
import PalmJebelAliGuidelines2 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_02.jpg"
import PalmJebelAliGuidelines3 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_03.jpg"
import PalmJebelAliGuidelines4 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_04.jpg"
import PalmJebelAliGuidelines5 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_05.jpg"
import PalmJebelAliGuidelines6 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_06.jpg"
import PalmJebelAliGuidelines7 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_07.jpg"
import PalmJebelAliGuidelines8 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_08.jpg"
import PalmJebelAliGuidelines9 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_09.jpg"
import PalmJebelAliGuidelines10 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_10.jpg"
import PalmJebelAliGuidelines11 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_11.jpg"
import PalmJebelAliGuidelines12 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_12.jpg"
import PalmJebelAliGuidelines13 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_13.jpg"
import PalmJebelAliGuidelines14 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_14.jpg"
import PalmJebelAliGuidelines15 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_15.jpg"

import PalmJebelAliPlotsDCR_1 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_01.jpg"
import PalmJebelAliPlotsDCR_2 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_02.jpg"
import PalmJebelAliPlotsDCR_3 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_03.jpg"
import PalmJebelAliPlotsDCR_4 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_04.jpg"
import PalmJebelAliPlotsDCR_5 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_05.jpg"
import PalmJebelAliPlotsDCR_6 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_06.jpg"
import PalmJebelAliPlotsDCR_7 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_07.jpg"
import PalmJebelAliPlotsDCR_8 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_08.jpg"
import PalmJebelAliPlotsDCR_9 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_09.jpg"
import PalmJebelAliPlotsDCR_10 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_10.jpg"
import PalmJebelAliPlotsDCR_11 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_11.jpg"
import PalmJebelAliPlotsDCR_12 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_12.jpg"
import PalmJebelAliPlotsDCR_13 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_13.jpg"
import PalmJebelAliPlotsDCR_14 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_14.jpg"
import PalmJebelAliPlotsDCR_15 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_15.jpg"

import PalmJebelAliPlotsPLC_1 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_01.jpg"
import PalmJebelAliPlotsPLC_2 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_02.jpg"
import PalmJebelAliPlotsPLC_3 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_03.jpg"
import PalmJebelAliPlotsPLC_4 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_04.jpg"
import PalmJebelAliPlotsPLC_5 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_05.jpg"
import PalmJebelAliPlotsPLC_6 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_06.jpg"
import PalmJebelAliPlotsPLC_7 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_07.jpg"
import PalmJebelAliPlotsPLC_8 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_08.jpg"
import PalmJebelAliPlotsPLC_9 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_09.jpg"
import PalmJebelAliPlotsPLC_10 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_10.jpg"
import PalmJebelAliPlotsPLC_11 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_11.jpg"
import PalmJebelAliPlotsPLC_12 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_12.jpg"
import PalmJebelAliPlotsPLC_13 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_13.jpg"
import PalmJebelAliPlotsPLC_14 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_14.jpg"
import PalmJebelAliPlotsPLC_15 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_15.jpg"

import PalmJebelAliMaterialBoardBrochure1 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_01.jpg"
import PalmJebelAliMaterialBoardBrochure2 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_02.jpg"
import PalmJebelAliMaterialBoardBrochure3 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_03.jpg"
import PalmJebelAliMaterialBoardBrochure4 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_04.jpg"
import PalmJebelAliMaterialBoardBrochure5 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_05.jpg"
import PalmJebelAliMaterialBoardBrochure6 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_06.jpg"
import PalmJebelAliMaterialBoardBrochure7 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_07.jpg"
import PalmJebelAliMaterialBoardBrochure8 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_08.jpg"
import PalmJebelAliMaterialBoardBrochure9 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_09.jpg"
import PalmJebelAliMaterialBoardBrochure10 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_10.jpg"
import PalmJebelAliMaterialBoardBrochure11 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_11.jpg"
import PalmJebelAliMaterialBoardBrochure12 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_12.jpg"
import PalmJebelAliMaterialBoardBrochure13 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_13.jpg"
import PalmJebelAliMaterialBoardBrochure14 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_14.jpg"
import PalmJebelAliMaterialBoardBrochure15 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_15.jpg"

import PalmJebelAliBrochure1 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_01.jpg"
import PalmJebelAliBrochure2 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_02.jpg"
import PalmJebelAliBrochure3 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_03.jpg"
import PalmJebelAliBrochure4 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_04.jpg"
import PalmJebelAliBrochure5 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_05.jpg"
import PalmJebelAliBrochure6 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_06.jpg"
import PalmJebelAliBrochure7 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_07.jpg"
import PalmJebelAliBrochure8 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_08.jpg"
import PalmJebelAliBrochure9 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_09.jpg"
import PalmJebelAliBrochure10 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_10.jpg"
import PalmJebelAliBrochure11 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_11.jpg"
import PalmJebelAliBrochure12 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_12.jpg"
import PalmJebelAliBrochure13 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_13.jpg"
import PalmJebelAliBrochure14 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_14.jpg"
import PalmJebelAliBrochure15 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_15.jpg"

import PalmJebelAliFloorPlansUKL1 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond IJKL/001 Acquamarina/Artboard 1.jpg"
import PalmJebelAliFloorPlansUKL2 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond IJKL/001 Acquamarina/Artboard 2.jpg"
import PalmJebelAliFloorPlansUKL3 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond IJKL/001 Acquamarina/PJA ALL Floorplans_Page_01.jpg"
import PalmJebelAliFloorPlansUKL4 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond IJKL/002 Azure Blue/Artboard 1.jpg"
import PalmJebelAliFloorPlansUKL5 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond IJKL/002 Azure Blue/Artboard 2.jpg"
import PalmJebelAliFloorPlansUKL6 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond IJKL/002 Azure Blue/PJA ALL Floorplans_Page_02.jpg"

import PalmJebelAliFloorPlansMNOP1 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond MNOP/001 Acquamarina/Artboard 1.jpg"
import PalmJebelAliFloorPlansMNOP2 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond MNOP/001 Acquamarina/Artboard 2.jpg"
import PalmJebelAliFloorPlansMNOP3 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond MNOP/001 Acquamarina/PJA ALL Floorplans_Page_03.jpg"

import PalmJebelAliMasterplan1 from "../../../static/images/files/Palm Jebel Ali/f. Masterplan/PJA Fronds IJKL Masterplan.jpg"
import PalmJebelAliMasterplan2 from "../../../static/images/files/Palm Jebel Ali/f. Masterplan/PJA Fronds MNOP Masterplan.jpg"

import MarkPalmJumeirah from '../../../static/images/markers/palm-jumeirah/mark-palm-jumeirah.svg'

import MarkVeneto from '../../../static/images/markers/veneto/mark-veneto.svg'
import MarkVenetoBlack from '../../../static/images/markers/veneto/mark-veneto-black.svg'
import VenetoPreview1 from '../../../static/images/markers/veneto/home-service-image_large1625984384.jpg'
import VenetoPreview2 from '../../../static/images/markers/veneto/home-service-image_large1625984413.jpg'
import VenetoPreview3 from '../../../static/images/markers/veneto/home-service-image_large1625984429.jpg'
import VenetoPreview4 from '../../../static/images/markers/veneto/home-service-image_large1625984444.jpg'


import MarkDubaiIslands from '../../../static/images/markers/dubai-islands/mark-dubai-islands.svg'

import DubaiIslandsBrochure1 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_01.jpg"
import DubaiIslandsBrochure2 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_02.jpg"
import DubaiIslandsBrochure3 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_03.jpg"
import DubaiIslandsBrochure4 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_04.jpg"
import DubaiIslandsBrochure5 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_05.jpg"
import DubaiIslandsBrochure6 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_06.jpg"
import DubaiIslandsBrochure7 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_07.jpg"
import DubaiIslandsBrochure8 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_08.jpg"
import DubaiIslandsBrochure9 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_09.jpg"
import DubaiIslandsBrochure10 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_10.jpg"
import DubaiIslandsBrochure11 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_11.jpg"
import DubaiIslandsBrochure12 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_12.jpg"
import DubaiIslandsBrochure13 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_13.jpg"
import DubaiIslandsBrochure14 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_14.jpg"
import DubaiIslandsBrochure15 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_15.jpg"

import DubaiIslandsMasterplan1 from "../../../static/images/files/Dubai Islands/b. Masterplan/Dubai Island A_Page_1.jpg"
import DubaiIslandsMasterplan2 from "../../../static/images/files/Dubai Islands/b. Masterplan/Dubai Island A_Page_2.jpg"

import DubaiIslandsPreview1 from '../../../static/images/markers/dubai-islands/nakheel_dubai_islands_deira_properties_for_sale_1.jpg'
import DubaiIslandsPreview2 from '../../../static/images/markers/dubai-islands/nakheel_dubai_islands_deira_properties_for_sale_4.jpg'
import DubaiIslandsVideo from '../../../static/images/markers/dubai-islands/Dubai Islands Video.mp4'


import MarkJebelAliVillage from '../../../static/images/markers/jebel-ali-village/jebel-ali-village.svg'
import JebelAliVillageVideo from '../../../static/images/markers/jebel-ali-village/Jebel Ali Village.mp4'
import JebelAliVillagePreview1 from '../../../static/images/markers/jebel-ali-village/Renders/Community/C1.jpg'
import JebelAliVillagePreview3 from '../../../static/images/markers/jebel-ali-village/Renders/Community/C3.jpg'
import JebelAliVillagePreview4 from '../../../static/images/markers/jebel-ali-village/Renders/Community/C4.jpg'
import JebelAliVillagePreview5 from '../../../static/images/markers/jebel-ali-village/Renders/Community/C5.jpg'
import JebelAliVillagePreview6 from '../../../static/images/markers/jebel-ali-village/Renders/Community/C6.jpg'
import JebelAliVillagePreview7 from '../../../static/images/markers/jebel-ali-village/Renders/Community/C7.jpg'
import JebelAliVillagePreview8 from '../../../static/images/markers/jebel-ali-village/Renders/Community/C8.jpg'
import JebelAliVillagePreview9 from '../../../static/images/markers/jebel-ali-village/Renders/Community/C9.jpg'

import JebelAliVillageBrochure1 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_01.jpg"
import JebelAliVillageBrochure2 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_02.jpg"
import JebelAliVillageBrochure3 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_03.jpg"
import JebelAliVillageBrochure4 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_04.jpg"
import JebelAliVillageBrochure5 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_05.jpg"
import JebelAliVillageBrochure6 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_06.jpg"
import JebelAliVillageBrochure7 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_07.jpg"
import JebelAliVillageBrochure8 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_08.jpg"
import JebelAliVillageBrochure9 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_09.jpg"
import JebelAliVillageBrochure10 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_10.jpg"
import JebelAliVillageBrochure11 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_11.jpg"
import JebelAliVillageBrochure12 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_12.jpg"
import JebelAliVillageBrochure13 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_13.jpg"
import JebelAliVillageBrochure14 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_14.jpg"
import JebelAliVillageBrochure15 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_15.jpg"

import JebelAliVillageFactSheet1 from "../../../static/images/files/Jebel Ali Village/b. FactSheet/JebelAliVillage_Factsheet hr_Page_1.jpg"
import JebelAliVillageFactSheet2 from "../../../static/images/files/Jebel Ali Village/b. FactSheet/JebelAliVillage_Factsheet hr_Page_2.jpg"

import JebelAliVillageFloorPlan1 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_01.jpg"
import JebelAliVillageFloorPlan2 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_02.jpg"
import JebelAliVillageFloorPlan3 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_03.jpg"
import JebelAliVillageFloorPlan4 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_04.jpg"
import JebelAliVillageFloorPlan5 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_05.jpg"
import JebelAliVillageFloorPlan6 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_06.jpg"
import JebelAliVillageFloorPlan7 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_07.jpg"
import JebelAliVillageFloorPlan8 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_08.jpg"
import JebelAliVillageFloorPlan9 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_09.jpg"
import JebelAliVillageFloorPlan10 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_10.jpg"
import JebelAliVillageFloorPlan11 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_11.jpg"
import JebelAliVillageFloorPlan12 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_12.jpg"
import JebelAliVillageFloorPlan13 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_13.jpg"
import JebelAliVillageFloorPlan14 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_14.jpg"
import JebelAliVillageFloorPlan15 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_15.jpg"

import JebelAliVillageMasterPlan from "../../../static/images/files/Jebel Ali Village/d. MasterPlan/JAV Masterplan full.jpg"
import JebelAliVillagePaymentPlan from "../../../static/images/files/Jebel Ali Village/e. Payment Plan/JAV Payment Plan.jpg"

import MarkJebelAliClub from '../../../static/images/markers/jebel-ali-club/jebel-ali-club.svg'
import MarkJumeirahPark from '../../../static/images/markers/jumeirah-park/jumeirah-park.svg'
import MarkJumeirahHeights from '../../../static/images/markers/jumeirah-heights/jumeirah-heights.svg'
import MarkJumeirahIslands from '../../../static/images/markers/jumeirah-islands/jumeirah-islands.svg'
import MarkAlFurjan from '../../../static/images/markers/al-furjan/al-furjan.svg'
import MarkMuroojAlFurjan from '../../../static/images/markers/murooj-al-furjan/murooj-al-furjan.svg'


import MarkTilalAlFurjan from '../../../static/images/markers/tilal-al-furjan/tilal-al-furjan.svg'
import TilalAlFurjanVideo from '../../../static/images/markers/tilal-al-furjan/Video.mp4'
import TilalAlFurjanPreview1 from '../../../static/images/markers/tilal-al-furjan/Renders/4B-A_Back_HC_RT5.jpg'
import TilalAlFurjanPreview2 from '../../../static/images/markers/tilal-al-furjan/Renders/4B-B_Back_HC_RT5.jpg'
import TilalAlFurjanPreview3 from '../../../static/images/markers/tilal-al-furjan/Renders/5B-B_Front_HC_RT4.jpg'
import TilalAlFurjanPreview4 from '../../../static/images/markers/tilal-al-furjan/Renders/5B-C_Front_HC_RT4.jpg'
import TilalAlFurjanPreview5 from '../../../static/images/markers/tilal-al-furjan/Renders/LIVING_4B-F_HC_RT4.jpg'

import TilalAlFurjanBrochure1 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_01.jpg"
import TilalAlFurjanBrochure2 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_02.jpg"
import TilalAlFurjanBrochure3 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_03.jpg"
import TilalAlFurjanBrochure4 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_04.jpg"
import TilalAlFurjanBrochure5 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_05.jpg"
import TilalAlFurjanBrochure6 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_06.jpg"
import TilalAlFurjanBrochure7 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_07.jpg"
import TilalAlFurjanBrochure8 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_08.jpg"
import TilalAlFurjanBrochure9 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_09.jpg"
import TilalAlFurjanBrochure10 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_10.jpg"
import TilalAlFurjanBrochure11 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_11.jpg"
import TilalAlFurjanBrochure12 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_12.jpg"
import TilalAlFurjanBrochure13 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_13.jpg"
import TilalAlFurjanBrochure14 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_14.jpg"
import TilalAlFurjanBrochure15 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_15.jpg"

import TilalAlFurjanFactSheet1 from "../../../static/images/files/Tilal Al Furjan/b. FactSheet/Fact Sheet_Page_1.jpg"
import TilalAlFurjanFactSheet2 from "../../../static/images/files/Tilal Al Furjan/b. FactSheet/Fact Sheet_Page_2.jpg"
import TilalAlFurjanFactSheet3 from "../../../static/images/files/Tilal Al Furjan/b. FactSheet/Fact Sheet_Page_3.jpg"

import MarkMasakinAlFurjan from '../../../static/images/markers/masakin-al-furjan/masakin-al-furjan.svg'
import MarkDiscoveryGardens from '../../../static/images/markers/discovery-gardens/discovery-gardens.svg'
import MarkJumeirahVillageTriangle from '../../../static/images/markers/jumeirah-village-triangle/jumeirah-village-triangle.svg'
import MarkJumeirahVillageCircle from '../../../static/images/markers/jumeirah-village-circle/jumeirah-village-circle.svg'


import LagoonViewsMark from '../../../static/images/markers/lagoon-views/lagoon-views.svg'
import LagoonViewsVideo from '../../../static/images/markers/lagoon-views/LV_Video_VO_Final_EN.mp4'
import LagoonViewsPreview1 from '../../../static/images/markers/lagoon-views/Renders/preview1.jpg'
import LagoonViewsPreview2 from '../../../static/images/markers/lagoon-views/Renders/preview2.jpg'
import LagoonViewsPreview3 from '../../../static/images/markers/lagoon-views/Renders/preview3.jpg'
import LagoonViewsPreview4 from '../../../static/images/markers/lagoon-views/Renders/preview4.jpg'
import LagoonViewsPreview5 from '../../../static/images/markers/lagoon-views/Renders/preview5.jpg'
import LagoonViewsPreview6 from '../../../static/images/markers/lagoon-views/Renders/preview6.jpg'

import LagoonViewsMasterplan from "../../../static/images/files/Lagoon Views/a. Masterplan/Lagoon Views at District One - Masterplan.jpg"

import LagoonViewsBrochure1 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_01.jpg"
import LagoonViewsBrochure2 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_02.jpg"
import LagoonViewsBrochure3 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_03.jpg"
import LagoonViewsBrochure4 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_04.jpg"
import LagoonViewsBrochure5 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_05.jpg"
import LagoonViewsBrochure6 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_06.jpg"
import LagoonViewsBrochure7 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_07.jpg"
import LagoonViewsBrochure8 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_08.jpg"
import LagoonViewsBrochure9 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_09.jpg"
import LagoonViewsBrochure10 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_10.jpg"
import LagoonViewsBrochure11 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_11.jpg"
import LagoonViewsBrochure12 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_12.jpg"
import LagoonViewsBrochure13 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_13.jpg"
import LagoonViewsBrochure14 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_14.jpg"
import LagoonViewsBrochure15 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_15.jpg"

import LagoonViewsFloorplan1 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_01.jpg"
import LagoonViewsFloorplan2 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_02.jpg"
import LagoonViewsFloorplan3 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_03.jpg"
import LagoonViewsFloorplan4 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_04.jpg"
import LagoonViewsFloorplan5 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_05.jpg"
import LagoonViewsFloorplan6 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_06.jpg"
import LagoonViewsFloorplan7 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_07.jpg"
import LagoonViewsFloorplan8 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_08.jpg"
import LagoonViewsFloorplan9 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_09.jpg"
import LagoonViewsFloorplan10 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_10.jpg"
import LagoonViewsFloorplan11 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_11.jpg"
import LagoonViewsFloorplan12 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_12.jpg"
import LagoonViewsFloorplan13 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_13.jpg"
import LagoonViewsFloorplan14 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_14.jpg"
import LagoonViewsFloorplan15 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_15.jpg"

import LagoonViewsPaymentPlan from "../../../static/images/files/Lagoon Views/d. Payment Plan/Lagoon Views at District One - Payment Plan.jpg"


import GreenwoodMark from '../../../static/images/markers/greenwood/greenwood.svg'
import GreenwoodVideo from '../../../static/images/markers/greenwood/video.mp4'

import GreenwoodPreview1 from '../../../static/images/markers/greenwood/renders/01.jpg'
import GreenwoodPreview2 from '../../../static/images/markers/greenwood/renders/02.jpg'
import GreenwoodPreview3 from '../../../static/images/markers/greenwood/renders/03.jpg'
import GreenwoodPreview4 from '../../../static/images/markers/greenwood/renders/04.jpg'
import GreenwoodPreview5 from '../../../static/images/markers/greenwood/renders/05.jpg'
import GreenwoodPreview6 from '../../../static/images/markers/greenwood/renders/06.jpg'
import GreenwoodPreview7 from '../../../static/images/markers/greenwood/renders/07.jpg'
import GreenwoodPreview8 from '../../../static/images/markers/greenwood/renders/08.jpg'
import GreenwoodPreview9 from '../../../static/images/markers/greenwood/renders/09.jpg'

import GreenwoodBrochure1 from "../../../static/images/markers/greenwood/GW Brochure JPG/GREENWOOD__CN_Page_01.jpg"
import GreenwoodBrochure2 from "../../../static/images/markers/greenwood/GW Brochure JPG/GREENWOOD__CN_Page_02.jpg"
import GreenwoodBrochure3 from "../../../static/images/markers/greenwood/GW Brochure JPG/GREENWOOD__CN_Page_03.jpg"
import GreenwoodBrochure4 from "../../../static/images/markers/greenwood/GW Brochure JPG/GREENWOOD__CN_Page_04.jpg"
import GreenwoodBrochure5 from "../../../static/images/markers/greenwood/GW Brochure JPG/GREENWOOD__CN_Page_05.jpg"
import GreenwoodBrochure6 from "../../../static/images/markers/greenwood/GW Brochure JPG/GREENWOOD__CN_Page_06.jpg"
import GreenwoodBrochure7 from "../../../static/images/markers/greenwood/GW Brochure JPG/GREENWOOD__CN_Page_07.jpg"
import GreenwoodBrochure8 from "../../../static/images/markers/greenwood/GW Brochure JPG/GREENWOOD__CN_Page_08.jpg"
import GreenwoodBrochure9 from "../../../static/images/markers/greenwood/GW Brochure JPG/GREENWOOD__CN_Page_09.jpg"
import GreenwoodBrochure10 from "../../../static/images/markers/greenwood/GW Brochure JPG/GREENWOOD__CN_Page_10.jpg"
import GreenwoodBrochure11 from "../../../static/images/markers/greenwood/GW Brochure JPG/GREENWOOD__CN_Page_11.jpg"
import GreenwoodBrochure12 from "../../../static/images/markers/greenwood/GW Brochure JPG/GREENWOOD__CN_Page_12.jpg"
import GreenwoodBrochure13 from "../../../static/images/markers/greenwood/GW Brochure JPG/GREENWOOD__CN_Page_13.jpg"
import GreenwoodBrochure14 from "../../../static/images/markers/greenwood/GW Brochure JPG/GREENWOOD__CN_Page_14.jpg"
import GreenwoodBrochure15 from "../../../static/images/markers/greenwood/GW Brochure JPG/GREENWOOD__CN_Page_15.jpg"


import NayaResidenceMark from '../../../static/images/markers/naya-residence/naya-residence.svg'
import NayaResidenceVideo from '../../../static/images/markers/naya-residence/video.mp4'
import NayaResidencePreview1 from '../../../static/images/markers/naya-residence/renders/01.jpg'
import NayaResidencePreview2 from '../../../static/images/markers/naya-residence/renders/02.jpg'
import NayaResidencePreview3 from '../../../static/images/markers/naya-residence/renders/03.jpg'
import NayaResidencePreview4 from '../../../static/images/markers/naya-residence/renders/04.jpg'
import NayaResidencePreview5 from '../../../static/images/markers/naya-residence/renders/05.jpg'
import NayaResidencePreview6 from '../../../static/images/markers/naya-residence/renders/06.jpg'
import NayaResidencePreview7 from '../../../static/images/markers/naya-residence/renders/07.jpg'
import NayaResidencePreview8 from '../../../static/images/markers/naya-residence/renders/08.jpg'
import NayaResidencePreview9 from '../../../static/images/markers/naya-residence/renders/09.jpg'
import NayaResidencePreview10 from '../../../static/images/markers/naya-residence/renders/10.jpg'

import NayaResidenceBrochure1 from "../../../static/images/markers/naya-residence/Naya Brochure JPG/Naya Brochure CN Hires_Page_01.jpg"
import NayaResidenceBrochure2 from "../../../static/images/markers/naya-residence/Naya Brochure JPG/Naya Brochure CN Hires_Page_02.jpg"
import NayaResidenceBrochure3 from "../../../static/images/markers/naya-residence/Naya Brochure JPG/Naya Brochure CN Hires_Page_03.jpg"
import NayaResidenceBrochure4 from "../../../static/images/markers/naya-residence/Naya Brochure JPG/Naya Brochure CN Hires_Page_04.jpg"
import NayaResidenceBrochure5 from "../../../static/images/markers/naya-residence/Naya Brochure JPG/Naya Brochure CN Hires_Page_05.jpg"
import NayaResidenceBrochure6 from "../../../static/images/markers/naya-residence/Naya Brochure JPG/Naya Brochure CN Hires_Page_06.jpg"
import NayaResidenceBrochure7 from "../../../static/images/markers/naya-residence/Naya Brochure JPG/Naya Brochure CN Hires_Page_07.jpg"
import NayaResidenceBrochure8 from "../../../static/images/markers/naya-residence/Naya Brochure JPG/Naya Brochure CN Hires_Page_08.jpg"
import NayaResidenceBrochure9 from "../../../static/images/markers/naya-residence/Naya Brochure JPG/Naya Brochure CN Hires_Page_09.jpg"
import NayaResidenceBrochure10 from "../../../static/images/markers/naya-residence/Naya Brochure JPG/Naya Brochure CN Hires_Page_10.jpg"
import NayaResidenceBrochure11 from "../../../static/images/markers/naya-residence/Naya Brochure JPG/Naya Brochure CN Hires_Page_11.jpg"
import NayaResidenceBrochure12 from "../../../static/images/markers/naya-residence/Naya Brochure JPG/Naya Brochure CN Hires_Page_12.jpg"
import NayaResidenceBrochure13 from "../../../static/images/markers/naya-residence/Naya Brochure JPG/Naya Brochure CN Hires_Page_13.jpg"
import NayaResidenceBrochure14 from "../../../static/images/markers/naya-residence/Naya Brochure JPG/Naya Brochure CN Hires_Page_14.jpg"
import NayaResidenceBrochure15 from "../../../static/images/markers/naya-residence/Naya Brochure JPG/Naya Brochure CN Hires_Page_15.jpg"

import NayaResidenceFloorPlansNaya1_1 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG//Naya 1/NAYA 1 - Floor Plan_Page_01.jpg"
import NayaResidenceFloorPlansNaya1_2 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_02.jpg"
import NayaResidenceFloorPlansNaya1_3 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_03.jpg"
import NayaResidenceFloorPlansNaya1_4 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_04.jpg"
import NayaResidenceFloorPlansNaya1_5 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_05.jpg"
import NayaResidenceFloorPlansNaya1_6 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_06.jpg"
import NayaResidenceFloorPlansNaya1_7 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_07.jpg"
import NayaResidenceFloorPlansNaya1_8 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_08.jpg"
import NayaResidenceFloorPlansNaya1_9 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_09.jpg"
import NayaResidenceFloorPlansNaya1_10 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_10.jpg"
import NayaResidenceFloorPlansNaya1_11 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_11.jpg"
import NayaResidenceFloorPlansNaya1_12 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_12.jpg"
import NayaResidenceFloorPlansNaya1_13 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_13.jpg"
import NayaResidenceFloorPlansNaya1_14 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_14.jpg"
import NayaResidenceFloorPlansNaya1_15 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_15.jpg"

import NayaResidenceFloorPlansNaya2_1 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_01.jpg"
import NayaResidenceFloorPlansNaya2_2 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_02.jpg"
import NayaResidenceFloorPlansNaya2_3 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_03.jpg"
import NayaResidenceFloorPlansNaya2_4 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_04.jpg"
import NayaResidenceFloorPlansNaya2_5 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_05.jpg"
import NayaResidenceFloorPlansNaya2_6 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_06.jpg"
import NayaResidenceFloorPlansNaya2_7 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_07.jpg"
import NayaResidenceFloorPlansNaya2_8 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_08.jpg"
import NayaResidenceFloorPlansNaya2_9 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_09.jpg"
import NayaResidenceFloorPlansNaya2_10 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_10.jpg"
import NayaResidenceFloorPlansNaya2_11 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_11.jpg"
import NayaResidenceFloorPlansNaya2_12 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_12.jpg"
import NayaResidenceFloorPlansNaya2_13 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_13.jpg"
import NayaResidenceFloorPlansNaya2_14 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_14.jpg"
import NayaResidenceFloorPlansNaya2_15 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_15.jpg"

import NayaResidenceFloorPlansNaya3_1 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_01.jpg"
import NayaResidenceFloorPlansNaya3_2 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_02.jpg"
import NayaResidenceFloorPlansNaya3_3 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_03.jpg"
import NayaResidenceFloorPlansNaya3_4 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_04.jpg"
import NayaResidenceFloorPlansNaya3_5 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_05.jpg"
import NayaResidenceFloorPlansNaya3_6 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_06.jpg"
import NayaResidenceFloorPlansNaya3_7 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_07.jpg"
import NayaResidenceFloorPlansNaya3_8 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_08.jpg"
import NayaResidenceFloorPlansNaya3_9 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_09.jpg"
import NayaResidenceFloorPlansNaya3_10 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_10.jpg"
import NayaResidenceFloorPlansNaya3_11 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_11.jpg"
import NayaResidenceFloorPlansNaya3_12 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_12.jpg"
import NayaResidenceFloorPlansNaya3_13 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_13.jpg"
import NayaResidenceFloorPlansNaya3_14 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_14.jpg"
import NayaResidenceFloorPlansNaya3_15 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_15.jpg"

import NayaResidenceFloorPlansNayaLagoonVillas1 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_01.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas2 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_02.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas3 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_03.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas4 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_04.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas5 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_05.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas6 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_06.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas7 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_07.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas8 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_08.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas9 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_09.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas10 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_10.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas11 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_11.jpg"


import RixosMark from '../../../static/images/markers/rixos/rixos.svg'
import Rixos1 from '../../../static/images/markers/rixos/renders/01.jpg'
import Rixos2 from '../../../static/images/markers/rixos/renders/02.jpg'
import Rixos3 from '../../../static/images/markers/rixos/renders/03.jpg'
import Rixos4 from '../../../static/images/markers/rixos/renders/04.jpg'
import Rixos5 from '../../../static/images/markers/rixos/renders/05.jpg'
import Rixos6 from '../../../static/images/markers/rixos/renders/06.jpg'
import Rixos7 from '../../../static/images/markers/rixos/renders/07.jpg'
import Rixos8 from '../../../static/images/markers/rixos/renders/08.jpg'
import Rixos9 from '../../../static/images/markers/rixos/renders/09.jpg'
import Rixos10 from '../../../static/images/markers/rixos/renders/10.jpg'
import RixosVideo from '../../../static/images/markers/rixos/Rixos Video.mp4'

import RixosBrochure1 from "../../../static/images/markers/rixos/Rixos Brochure JPG/Rixos_BeachResidences_FullBrochure_CH_Page_01.jpg"
import RixosBrochure2 from "../../../static/images/markers/rixos/Rixos Brochure JPG/Rixos_BeachResidences_FullBrochure_CH_Page_02.jpg"
import RixosBrochure3 from "../../../static/images/markers/rixos/Rixos Brochure JPG/Rixos_BeachResidences_FullBrochure_CH_Page_03.jpg"
import RixosBrochure4 from "../../../static/images/markers/rixos/Rixos Brochure JPG/Rixos_BeachResidences_FullBrochure_CH_Page_04.jpg"
import RixosBrochure5 from "../../../static/images/markers/rixos/Rixos Brochure JPG/Rixos_BeachResidences_FullBrochure_CH_Page_05.jpg"
import RixosBrochure6 from "../../../static/images/markers/rixos/Rixos Brochure JPG/Rixos_BeachResidences_FullBrochure_CH_Page_06.jpg"
import RixosBrochure7 from "../../../static/images/markers/rixos/Rixos Brochure JPG/Rixos_BeachResidences_FullBrochure_CH_Page_07.jpg"
import RixosBrochure8 from "../../../static/images/markers/rixos/Rixos Brochure JPG/Rixos_BeachResidences_FullBrochure_CH_Page_08.jpg"
import RixosBrochure9 from "../../../static/images/markers/rixos/Rixos Brochure JPG/Rixos_BeachResidences_FullBrochure_CH_Page_09.jpg"
import RixosBrochure10 from "../../../static/images/markers/rixos/Rixos Brochure JPG/Rixos_BeachResidences_FullBrochure_CH_Page_10.jpg"
import RixosBrochure11 from "../../../static/images/markers/rixos/Rixos Brochure JPG/Rixos_BeachResidences_FullBrochure_CH_Page_11.jpg"
import RixosBrochure12 from "../../../static/images/markers/rixos/Rixos Brochure JPG/Rixos_BeachResidences_FullBrochure_CH_Page_12.jpg"
import RixosBrochure13 from "../../../static/images/markers/rixos/Rixos Brochure JPG/Rixos_BeachResidences_FullBrochure_CH_Page_13.jpg"
import RixosBrochure14 from "../../../static/images/markers/rixos/Rixos Brochure JPG/Rixos_BeachResidences_FullBrochure_CH_Page_14.jpg"
import RixosBrochure15 from "../../../static/images/markers/rixos/Rixos Brochure JPG/Rixos_BeachResidences_FullBrochure_CH_Page_15.jpg"

import RixosFloorPlansB2_1 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B2/B2_Page_01.jpg"
import RixosFloorPlansB2_2 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B2/B2_Page_02.jpg"
import RixosFloorPlansB2_3 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B2/B2_Page_03.jpg"
import RixosFloorPlansB2_4 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B2/B2_Page_04.jpg"
import RixosFloorPlansB2_5 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B2/B2_Page_05.jpg"
import RixosFloorPlansB2_6 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B2/B2_Page_06.jpg"
import RixosFloorPlansB2_7 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B2/B2_Page_07.jpg"
import RixosFloorPlansB2_8 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B2/B2_Page_08.jpg"
import RixosFloorPlansB2_9 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B2/B2_Page_09.jpg"
import RixosFloorPlansB2_10 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B2/B2_Page_10.jpg"
import RixosFloorPlansB2_11 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B2/B2_Page_11.jpg"
import RixosFloorPlansB2_12 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B2/B2_Page_12.jpg"
import RixosFloorPlansB2_13 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B2/B2_Page_13.jpg"
import RixosFloorPlansB2_14 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B2/B2_Page_14.jpg"
import RixosFloorPlansB2_15 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B2/B2_Page_15.jpg"

import RixosFloorPlansB3_1 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B3/B3_Page_01.jpg"
import RixosFloorPlansB3_2 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B3/B3_Page_02.jpg"
import RixosFloorPlansB3_3 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B3/B3_Page_03.jpg"
import RixosFloorPlansB3_4 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B3/B3_Page_04.jpg"
import RixosFloorPlansB3_5 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B3/B3_Page_05.jpg"
import RixosFloorPlansB3_6 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B3/B3_Page_06.jpg"
import RixosFloorPlansB3_7 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B3/B3_Page_07.jpg"
import RixosFloorPlansB3_8 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B3/B3_Page_08.jpg"
import RixosFloorPlansB3_9 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B3/B3_Page_09.jpg"
import RixosFloorPlansB3_10 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B3/B3_Page_10.jpg"
import RixosFloorPlansB3_11 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B3/B3_Page_11.jpg"
import RixosFloorPlansB3_12 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B3/B3_Page_12.jpg"
import RixosFloorPlansB3_13 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B3/B3_Page_13.jpg"
import RixosFloorPlansB3_14 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B3/B3_Page_14.jpg"
import RixosFloorPlansB3_15 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B3/B3_Page_15.jpg"

import RixosFloorPlansB4_1 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B4/B4_Page_01.jpg"
import RixosFloorPlansB4_2 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B4/B4_Page_02.jpg"
import RixosFloorPlansB4_3 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B4/B4_Page_03.jpg"
import RixosFloorPlansB4_4 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B4/B4_Page_04.jpg"
import RixosFloorPlansB4_5 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B4/B4_Page_05.jpg"
import RixosFloorPlansB4_6 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B4/B4_Page_06.jpg"
import RixosFloorPlansB4_7 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B4/B4_Page_07.jpg"
import RixosFloorPlansB4_8 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B4/B4_Page_08.jpg"
import RixosFloorPlansB4_9 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B4/B4_Page_09.jpg"
import RixosFloorPlansB4_10 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B4/B4_Page_10.jpg"
import RixosFloorPlansB4_11 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B4/B4_Page_11.jpg"
import RixosFloorPlansB4_12 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B4/B4_Page_12.jpg"
import RixosFloorPlansB4_13 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B4/B4_Page_13.jpg"
import RixosFloorPlansB4_14 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B4/B4_Page_14.jpg"
import RixosFloorPlansB4_15 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/B4/B4_Page_15.jpg"

import RixosFloorPlansP2_1 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/P2/P2_Page_01.jpg"
import RixosFloorPlansP2_2 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/P2/P2_Page_02.jpg"
import RixosFloorPlansP2_3 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/P2/P2_Page_03.jpg"
import RixosFloorPlansP2_4 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/P2/P2_Page_04.jpg"
import RixosFloorPlansP2_5 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/P2/P2_Page_05.jpg"
import RixosFloorPlansP2_6 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/P2/P2_Page_06.jpg"
import RixosFloorPlansP2_7 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/P2/P2_Page_07.jpg"
import RixosFloorPlansP2_8 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/P2/P2_Page_08.jpg"
import RixosFloorPlansP2_9 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/P2/P2_Page_09.jpg"
import RixosFloorPlansP2_10 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/P2/P2_Page_10.jpg"
import RixosFloorPlansP2_11 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/P2/P2_Page_11.jpg"
import RixosFloorPlansP2_12 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/P2/P2_Page_12.jpg"
import RixosFloorPlansP2_13 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/P2/P2_Page_13.jpg"
import RixosFloorPlansP2_14 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/P2/P2_Page_14.jpg"
import RixosFloorPlansP2_15 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/P2/P2_Page_15.jpg"

import RixosFloorPlansBeachHouses1 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/Beach Houses/BEACH HOUSES_Page_01.jpg"
import RixosFloorPlansBeachHouses2 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/Beach Houses/BEACH HOUSES_Page_02.jpg"
import RixosFloorPlansBeachHouses3 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/Beach Houses/BEACH HOUSES_Page_03.jpg"
import RixosFloorPlansBeachHouses4 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/Beach Houses/BEACH HOUSES_Page_04.jpg"
import RixosFloorPlansBeachHouses5 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/Beach Houses/BEACH HOUSES_Page_05.jpg"
import RixosFloorPlansBeachHouses6 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/Beach Houses/BEACH HOUSES_Page_06.jpg"
import RixosFloorPlansBeachHouses7 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/Beach Houses/BEACH HOUSES_Page_07.jpg"
import RixosFloorPlansBeachHouses8 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/Beach Houses/BEACH HOUSES_Page_08.jpg"
import RixosFloorPlansBeachHouses9 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/Beach Houses/BEACH HOUSES_Page_09.jpg"
import RixosFloorPlansBeachHouses10 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/Beach Houses/BEACH HOUSES_Page_10.jpg"
import RixosFloorPlansBeachHouses11 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/Beach Houses/BEACH HOUSES_Page_11.jpg"
import RixosFloorPlansBeachHouses12 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/Beach Houses/BEACH HOUSES_Page_12.jpg"
import RixosFloorPlansBeachHouses13 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/Beach Houses/BEACH HOUSES_Page_13.jpg"
import RixosFloorPlansBeachHouses14 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/Beach Houses/BEACH HOUSES_Page_14.jpg"
import RixosFloorPlansBeachHouses15 from "../../../static/images/markers/rixos/Rixos Floorplan JPG/Beach Houses/BEACH HOUSES_Page_15.jpg"


import DistrictOneMark from '../../../static/images/markers/district-one-west-1/district-one-west-1.svg'
import DistrictOne1 from '../../../static/images/markers/district-one-west-1/2023-09-18-97.jpg'
import DistrictOne2 from '../../../static/images/markers/district-one-west-1/2023-09-18-87.jpg'
import DistrictOne3 from '../../../static/images/markers/district-one-west-1/2023-09-18-81.jpg'
import DistrictOne4 from '../../../static/images/markers/district-one-west-1/2023-09-18-73.jpg'
import DistrictOneVideo from '../../../static/images/markers/district-one-west-1/d1west_long_shortfilm_2_rev03.mp4'
import DistrictOneMasterplan from '../../../static/images/files/D1 West/c. Masterplan/D1 West Masterplan.jpg'

import DistrictOneFloorPlans1 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_01.jpg"
import DistrictOneFloorPlans2 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_02.jpg"
import DistrictOneFloorPlans3 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_03.jpg"
import DistrictOneFloorPlans4 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_04.jpg"
import DistrictOneFloorPlans5 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_05.jpg"
import DistrictOneFloorPlans6 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_06.jpg"
import DistrictOneFloorPlans7 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_07.jpg"
import DistrictOneFloorPlans8 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_08.jpg"
import DistrictOneFloorPlans9 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_09.jpg"
import DistrictOneFloorPlans10 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_10.jpg"
import DistrictOneFloorPlans11 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_11.jpg"
import DistrictOneFloorPlans12 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_12.jpg"
import DistrictOneFloorPlans13 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_13.jpg"
import DistrictOneFloorPlans14 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_14.jpg"
import DistrictOneFloorPlans15 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_15.jpg"

import DistrictOneBrochure1 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_01.jpg"
import DistrictOneBrochure2 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_02.jpg"
import DistrictOneBrochure3 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_03.jpg"
import DistrictOneBrochure4 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_04.jpg"
import DistrictOneBrochure5 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_05.jpg"
import DistrictOneBrochure6 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_06.jpg"
import DistrictOneBrochure7 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_07.jpg"
import DistrictOneBrochure8 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_08.jpg"
import DistrictOneBrochure9 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_09.jpg"
import DistrictOneBrochure10 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_10.jpg"
import DistrictOneBrochure11 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_11.jpg"
import DistrictOneBrochure12 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_12.jpg"
import DistrictOneBrochure13 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_13.jpg"
import DistrictOneBrochure14 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_14.jpg"
import DistrictOneBrochure15 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_15.jpg"

import DistrictOneMaterialBoardBrochure1 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_01.jpg"
import DistrictOneMaterialBoardBrochure2 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_02.jpg"
import DistrictOneMaterialBoardBrochure3 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_03.jpg"
import DistrictOneMaterialBoardBrochure4 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_04.jpg"
import DistrictOneMaterialBoardBrochure5 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_05.jpg"
import DistrictOneMaterialBoardBrochure6 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_06.jpg"
import DistrictOneMaterialBoardBrochure7 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_07.jpg"
import DistrictOneMaterialBoardBrochure8 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_08.jpg"
import DistrictOneMaterialBoardBrochure9 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_09.jpg"
import DistrictOneMaterialBoardBrochure10 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_10.jpg"
import DistrictOneMaterialBoardBrochure11 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_11.jpg"
import DistrictOneMaterialBoardBrochure12 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_12.jpg"


import MarkComoResidences from '../../../static/images/markers/como-residences/como-residences.svg'
import VideoComoResidences from '../../../static/images/markers/como-residences/Como_Residences_Animation.mp4'
import ComoResidencesPreview1 from '../../../static/images/markers/como-residences/renders/01.jpg'
import ComoResidencesPreview2 from '../../../static/images/markers/como-residences/renders/02.jpg'
import ComoResidencesPreview3 from '../../../static/images/markers/como-residences/renders/03.jpg'
import ComoResidencesPreview4 from '../../../static/images/markers/como-residences/renders/04.jpg'
import ComoResidencesPreview5 from '../../../static/images/markers/como-residences/renders/05.jpg'
import ComoResidencesPreview6 from '../../../static/images/markers/como-residences/renders/06.jpg'
import ComoResidencesPreview7 from '../../../static/images/markers/como-residences/renders/07.jpg'
import ComoResidencesPreview8 from '../../../static/images/markers/como-residences/renders/08.jpg'
import ComoResidencesPreview9 from '../../../static/images/markers/como-residences/renders/09.jpg'
import ComoResidencesPreview10 from '../../../static/images/markers/como-residences/renders/10.jpg'

import ComoResidencesBrochure1 from "../../../static/images/markers/como-residences/Como Brochure JPG/ComoResidences_Brochure__CN_V2_Page_01.jpg"
import ComoResidencesBrochure2 from "../../../static/images/markers/como-residences/Como Brochure JPG/ComoResidences_Brochure__CN_V2_Page_02.jpg"
import ComoResidencesBrochure3 from "../../../static/images/markers/como-residences/Como Brochure JPG/ComoResidences_Brochure__CN_V2_Page_03.jpg"
import ComoResidencesBrochure4 from "../../../static/images/markers/como-residences/Como Brochure JPG/ComoResidences_Brochure__CN_V2_Page_04.jpg"
import ComoResidencesBrochure5 from "../../../static/images/markers/como-residences/Como Brochure JPG/ComoResidences_Brochure__CN_V2_Page_05.jpg"
import ComoResidencesBrochure6 from "../../../static/images/markers/como-residences/Como Brochure JPG/ComoResidences_Brochure__CN_V2_Page_06.jpg"
import ComoResidencesBrochure7 from "../../../static/images/markers/como-residences/Como Brochure JPG/ComoResidences_Brochure__CN_V2_Page_07.jpg"
import ComoResidencesBrochure8 from "../../../static/images/markers/como-residences/Como Brochure JPG/ComoResidences_Brochure__CN_V2_Page_08.jpg"
import ComoResidencesBrochure9 from "../../../static/images/markers/como-residences/Como Brochure JPG/ComoResidences_Brochure__CN_V2_Page_09.jpg"
import ComoResidencesBrochure10 from "../../../static/images/markers/como-residences/Como Brochure JPG/ComoResidences_Brochure__CN_V2_Page_10.jpg"
import ComoResidencesBrochure11 from "../../../static/images/markers/como-residences/Como Brochure JPG/ComoResidences_Brochure__CN_V2_Page_11.jpg"
import ComoResidencesBrochure12 from "../../../static/images/markers/como-residences/Como Brochure JPG/ComoResidences_Brochure__CN_V2_Page_12.jpg"
import ComoResidencesBrochure13 from "../../../static/images/markers/como-residences/Como Brochure JPG/ComoResidences_Brochure__CN_V2_Page_13.jpg"
import ComoResidencesBrochure14 from "../../../static/images/markers/como-residences/Como Brochure JPG/ComoResidences_Brochure__CN_V2_Page_14.jpg"
import ComoResidencesBrochure15 from "../../../static/images/markers/como-residences/Como Brochure JPG/ComoResidences_Brochure__CN_V2_Page_15.jpg"

import ComoResidencesFloorPlan1 from "../../../static/images/markers/como-residences/Como Floorplan JPG/Como Floor Plan - Individuals_Page_01.jpg"
import ComoResidencesFloorPlan2 from "../../../static/images/markers/como-residences/Como Floorplan JPG/Como Floor Plan - Individuals_Page_02.jpg"
import ComoResidencesFloorPlan3 from "../../../static/images/markers/como-residences/Como Floorplan JPG/Como Floor Plan - Individuals_Page_03.jpg"
import ComoResidencesFloorPlan4 from "../../../static/images/markers/como-residences/Como Floorplan JPG/Como Floor Plan - Individuals_Page_04.jpg"
import ComoResidencesFloorPlan5 from "../../../static/images/markers/como-residences/Como Floorplan JPG/Como Floor Plan - Individuals_Page_05.jpg"
import ComoResidencesFloorPlan6 from "../../../static/images/markers/como-residences/Como Floorplan JPG/Como Floor Plan - Individuals_Page_06.jpg"
import ComoResidencesFloorPlan7 from "../../../static/images/markers/como-residences/Como Floorplan JPG/Como Floor Plan - Individuals_Page_07.jpg"
import ComoResidencesFloorPlan8 from "../../../static/images/markers/como-residences/Como Floorplan JPG/Como Floor Plan - Individuals_Page_08.jpg"
import ComoResidencesFloorPlan9 from "../../../static/images/markers/como-residences/Como Floorplan JPG/Como Floor Plan - Individuals_Page_09.jpg"
import ComoResidencesFloorPlan10 from "../../../static/images/markers/como-residences/Como Floorplan JPG/Como Floor Plan - Individuals_Page_10.jpg"
import ComoResidencesFloorPlan11 from "../../../static/images/markers/como-residences/Como Floorplan JPG/Como Floor Plan - Individuals_Page_11.jpg"
import ComoResidencesFloorPlan12 from "../../../static/images/markers/como-residences/Como Floorplan JPG/Como Floor Plan - Individuals_Page_12.jpg"
import ComoResidencesFloorPlan13 from "../../../static/images/markers/como-residences/Como Floorplan JPG/Como Floor Plan - Individuals_Page_13.jpg"
import ComoResidencesFloorPlan14 from "../../../static/images/markers/como-residences/Como Floorplan JPG/Como Floor Plan - Individuals_Page_14.jpg"
import ComoResidencesFloorPlan15 from "../../../static/images/markers/como-residences/Como Floorplan JPG/Como Floor Plan - Individuals_Page_15.jpg"


import MarkThePalmTowerResidences from '../../../static/images/markers/the-palm-tower-residences/the-palm-tower-residences.svg'
import MarkStRegisDubaiThePalm from '../../../static/images/markers/st-regis-dubai-the-palm/st-regis-dubai-the-palm.svg'


import MarkPalmBeachTowers from '../../../static/images/markers/palm-beach-towers/palm-beach-towers.svg'
import PalmBeachTowersVideo from '../../../static/images/markers/palm-beach-towers/video.mp4'

import PalmBeachTowersPreview1 from '../../../static/images/markers/palm-beach-towers/renders/01.jpg'
import PalmBeachTowersPreview2 from '../../../static/images/markers/palm-beach-towers/renders/02.jpg'
import PalmBeachTowersPreview3 from '../../../static/images/markers/palm-beach-towers/renders/03.jpg'
import PalmBeachTowersPreview4 from '../../../static/images/markers/palm-beach-towers/renders/04.jpg'
import PalmBeachTowersPreview5 from '../../../static/images/markers/palm-beach-towers/renders/05.jpg'
import PalmBeachTowersPreview6 from '../../../static/images/markers/palm-beach-towers/renders/06.jpg'
import PalmBeachTowersPreview7 from '../../../static/images/markers/palm-beach-towers/renders/07.jpg'
import PalmBeachTowersPreview8 from '../../../static/images/markers/palm-beach-towers/renders/08.jpg'
import PalmBeachTowersPreview9 from '../../../static/images/markers/palm-beach-towers/renders/09.jpg'
import PalmBeachTowersPreview10 from '../../../static/images/markers/palm-beach-towers/renders/10.jpg'

import PalmBeachTowersBrochure1 from "../../../static/images/markers/palm-beach-towers/PBT Brochure JPG/Palm Beact Tower 3 brochure CH_Page_01.jpg"
import PalmBeachTowersBrochure2 from "../../../static/images/markers/palm-beach-towers/PBT Brochure JPG/Palm Beact Tower 3 brochure CH_Page_02.jpg"
import PalmBeachTowersBrochure3 from "../../../static/images/markers/palm-beach-towers/PBT Brochure JPG/Palm Beact Tower 3 brochure CH_Page_03.jpg"
import PalmBeachTowersBrochure4 from "../../../static/images/markers/palm-beach-towers/PBT Brochure JPG/Palm Beact Tower 3 brochure CH_Page_04.jpg"
import PalmBeachTowersBrochure5 from "../../../static/images/markers/palm-beach-towers/PBT Brochure JPG/Palm Beact Tower 3 brochure CH_Page_05.jpg"
import PalmBeachTowersBrochure6 from "../../../static/images/markers/palm-beach-towers/PBT Brochure JPG/Palm Beact Tower 3 brochure CH_Page_06.jpg"
import PalmBeachTowersBrochure7 from "../../../static/images/markers/palm-beach-towers/PBT Brochure JPG/Palm Beact Tower 3 brochure CH_Page_07.jpg"
import PalmBeachTowersBrochure8 from "../../../static/images/markers/palm-beach-towers/PBT Brochure JPG/Palm Beact Tower 3 brochure CH_Page_08.jpg"
import PalmBeachTowersBrochure9 from "../../../static/images/markers/palm-beach-towers/PBT Brochure JPG/Palm Beact Tower 3 brochure CH_Page_09.jpg"
import PalmBeachTowersBrochure10 from "../../../static/images/markers/palm-beach-towers/PBT Brochure JPG/Palm Beact Tower 3 brochure CH_Page_10.jpg"
import PalmBeachTowersBrochure11 from "../../../static/images/markers/palm-beach-towers/PBT Brochure JPG/Palm Beact Tower 3 brochure CH_Page_11.jpg"
import PalmBeachTowersBrochure12 from "../../../static/images/markers/palm-beach-towers/PBT Brochure JPG/Palm Beact Tower 3 brochure CH_Page_12.jpg"
import PalmBeachTowersBrochure13 from "../../../static/images/markers/palm-beach-towers/PBT Brochure JPG/Palm Beact Tower 3 brochure CH_Page_13.jpg"
import PalmBeachTowersBrochure14 from "../../../static/images/markers/palm-beach-towers/PBT Brochure JPG/Palm Beact Tower 3 brochure CH_Page_14.jpg"
import PalmBeachTowersBrochure15 from "../../../static/images/markers/palm-beach-towers/PBT Brochure JPG/Palm Beact Tower 3 brochure CH_Page_15.jpg"


import MarkTheWorld from '../../../static/images/markers/the-world/the-world.svg'
import MarkNadAlShebaVillas from '../../../static/images/markers/nad-al-sheba-villas/nad-al-sheba-villas.svg'
import MarkWarsanVillage from '../../../static/images/markers/warsan-village/warsan-village.svg'
import MarkInternationalCity from '../../../static/images/markers/international-city/international-city.svg'


import MarkCanalFrontResidences from '../../../static/images/markers/canal-front-residences/canal-front-residences.svg'
import CanalFrontResidencesVideo from '../../../static/images/markers/canal-front-residences/Canal Front Residence 6 Video.mp4'
import CanalFrontResidencesPreview1 from '../../../static/images/markers/canal-front-residences/Images/preview1.jpg'
import CanalFrontResidencesPreview2 from '../../../static/images/markers/canal-front-residences/Images/preview2.jpg'
import CanalFrontResidencesPreview3 from '../../../static/images/markers/canal-front-residences/Images/preview3.jpg'
import CanalFrontResidencesPreview4 from '../../../static/images/markers/canal-front-residences/Images/preview4.jpg'
import CanalFrontResidencesPreview5 from '../../../static/images/markers/canal-front-residences/Images/preview5.jpg'

import CanalFrontResidencesBrochure1 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_01.jpg"
import CanalFrontResidencesBrochure2 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_02.jpg"
import CanalFrontResidencesBrochure3 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_03.jpg"
import CanalFrontResidencesBrochure4 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_04.jpg"
import CanalFrontResidencesBrochure5 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_05.jpg"
import CanalFrontResidencesBrochure6 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_06.jpg"
import CanalFrontResidencesBrochure7 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_07.jpg"
import CanalFrontResidencesBrochure8 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_08.jpg"
import CanalFrontResidencesBrochure9 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_09.jpg"
import CanalFrontResidencesBrochure10 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_10.jpg"
import CanalFrontResidencesBrochure11 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_11.jpg"
import CanalFrontResidencesBrochure12 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_12.jpg"
import CanalFrontResidencesBrochure13 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_13.jpg"
import CanalFrontResidencesBrochure14 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_14.jpg"
import CanalFrontResidencesBrochure15 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_15.jpg"

import CanalFrontResidencesFloorPlans1 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_01.jpg"
import CanalFrontResidencesFloorPlans2 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_02.jpg"
import CanalFrontResidencesFloorPlans3 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_03.jpg"
import CanalFrontResidencesFloorPlans4 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_04.jpg"
import CanalFrontResidencesFloorPlans5 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_05.jpg"
import CanalFrontResidencesFloorPlans6 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_06.jpg"
import CanalFrontResidencesFloorPlans7 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_07.jpg"
import CanalFrontResidencesFloorPlans8 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_08.jpg"
import CanalFrontResidencesFloorPlans9 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_09.jpg"
import CanalFrontResidencesFloorPlans10 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_10.jpg"
import CanalFrontResidencesFloorPlans11 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_11.jpg"
import CanalFrontResidencesFloorPlans12 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_12.jpg"
import CanalFrontResidencesFloorPlans13 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_13.jpg"
import CanalFrontResidencesFloorPlans14 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_14.jpg"
import CanalFrontResidencesFloorPlans15 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_15.jpg"

import CanalFrontResidencesFactSheet1 from "../../../static/images/files/Canal Front Residences/c. FactSheet/Canal Front Residence 6 Factsheet_Page_1.jpg"
import CanalFrontResidencesFactSheet2 from "../../../static/images/files/Canal Front Residences/c. FactSheet/Canal Front Residence 6 Factsheet_Page_2.jpg"
import CanalFrontResidencesFactSheet3 from "../../../static/images/files/Canal Front Residences/c. FactSheet/Canal Front Residence 6 Factsheet_Page_3.jpg"
import CanalFrontResidencesFactSheet4 from "../../../static/images/files/Canal Front Residences/c. FactSheet/Canal Front Residence 6 Factsheet_Page_4.jpg"
import CanalFrontResidencesFactSheet5 from "../../../static/images/files/Canal Front Residences/c. FactSheet/Canal Front Residence 6 Factsheet_Page_5.jpg"

import CanalFrontResidencesPaymentPlan from "../../../static/images/files/Canal Front Residences/d. Payment Plan/Canal Front Residence 6 Payment Plan.jpg"


import MarkDistrictOne from '../../../static/images/markers/district-one/district-one.svg'


import MarkDistrictOneWest from '../../../static/images/markers/district-one-west/district-one-west.svg'
import DistrictOneWestPreview1 from '../../../static/images/markers/district-one-west-1/Renders/preview1.jpg'
import DistrictOneWestPreview2 from '../../../static/images/markers/district-one-west-1/Renders/7.jpg'
import DistrictOneWestPreview3 from '../../../static/images/markers/district-one-west-1/Renders/preview2.jpg'
import DistrictOneWestPreview4 from '../../../static/images/markers/district-one-west-1/Renders/preview3.jpg'
import DistrictOneWestPreview5 from '../../../static/images/markers/district-one-west-1/Renders/preview4.jpg'
import DistrictOneWestPreview6 from '../../../static/images/markers/district-one-west-1/Renders/preview5.jpg'


import MarkMeydanAvenue from '../../../static/images/markers/meydan-avenue/meydan-avenue.svg'
import MeydanAvenuePreview1 from '../../../static/images/markers/meydan-avenue/Renders/preview1.jpg'
import MeydanAvenuePreview2 from '../../../static/images/markers/meydan-avenue/Renders/preview2.jpg'
import MeydanAvenuePreview3 from '../../../static/images/markers/meydan-avenue/Renders/preview3.jpg'
import MeydanAvenuePreview4 from '../../../static/images/markers/meydan-avenue/Renders/preview4.jpg'
import MeydanAvenuePreview5 from '../../../static/images/markers/meydan-avenue/Renders/preview5.jpg'
import MeydanAvenuePreview6 from '../../../static/images/markers/meydan-avenue/Renders/preview6.jpg'

import MeydanAvenuePlotABrochure1 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_01.jpg"
import MeydanAvenuePlotABrochure2 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_02.jpg"
import MeydanAvenuePlotABrochure3 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_03.jpg"
import MeydanAvenuePlotABrochure4 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_04.jpg"
import MeydanAvenuePlotABrochure5 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_05.jpg"
import MeydanAvenuePlotABrochure6 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_06.jpg"
import MeydanAvenuePlotABrochure7 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_07.jpg"
import MeydanAvenuePlotABrochure8 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_08.jpg"
import MeydanAvenuePlotABrochure9 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_09.jpg"
import MeydanAvenuePlotABrochure10 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_10.jpg"
import MeydanAvenuePlotABrochure11 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_11.jpg"
import MeydanAvenuePlotABrochure12 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_12.jpg"
import MeydanAvenuePlotABrochure13 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_13.jpg"
import MeydanAvenuePlotABrochure14 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_14.jpg"
import MeydanAvenuePlotABrochure15 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_15.jpg"

import MeydanAvenuePlotAPlotDesign1 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_01.jpg"
import MeydanAvenuePlotAPlotDesign2 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_02.jpg"
import MeydanAvenuePlotAPlotDesign3 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_03.jpg"
import MeydanAvenuePlotAPlotDesign4 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_04.jpg"
import MeydanAvenuePlotAPlotDesign5 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_05.jpg"
import MeydanAvenuePlotAPlotDesign6 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_06.jpg"
import MeydanAvenuePlotAPlotDesign7 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_07.jpg"
import MeydanAvenuePlotAPlotDesign8 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_08.jpg"
import MeydanAvenuePlotAPlotDesign9 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_09.jpg"
import MeydanAvenuePlotAPlotDesign10 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_10.jpg"
import MeydanAvenuePlotAPlotDesign11 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_11.jpg"
import MeydanAvenuePlotAPlotDesign12 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_12.jpg"
import MeydanAvenuePlotAPlotDesign13 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_13.jpg"
import MeydanAvenuePlotAPlotDesign14 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_14.jpg"
import MeydanAvenuePlotAPlotDesign15 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_15.jpg"

import MeydanAvenuePlotADevControl1 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_01.jpg"
import MeydanAvenuePlotADevControl2 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_02.jpg"
import MeydanAvenuePlotADevControl3 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_03.jpg"
import MeydanAvenuePlotADevControl4 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_04.jpg"
import MeydanAvenuePlotADevControl5 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_05.jpg"
import MeydanAvenuePlotADevControl6 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_06.jpg"
import MeydanAvenuePlotADevControl7 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_07.jpg"
import MeydanAvenuePlotADevControl8 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_08.jpg"
import MeydanAvenuePlotADevControl9 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_09.jpg"
import MeydanAvenuePlotADevControl10 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_10.jpg"
import MeydanAvenuePlotADevControl11 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_11.jpg"
import MeydanAvenuePlotADevControl12 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_12.jpg"
import MeydanAvenuePlotADevControl13 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_13.jpg"
import MeydanAvenuePlotADevControl14 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_14.jpg"
import MeydanAvenuePlotADevControl15 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_15.jpg"

import MeydanAvenuePlotBBrochure1 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_01.jpg"
import MeydanAvenuePlotBBrochure2 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_02.jpg"
import MeydanAvenuePlotBBrochure3 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_03.jpg"
import MeydanAvenuePlotBBrochure4 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_04.jpg"
import MeydanAvenuePlotBBrochure5 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_05.jpg"
import MeydanAvenuePlotBBrochure6 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_06.jpg"
import MeydanAvenuePlotBBrochure7 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_07.jpg"
import MeydanAvenuePlotBBrochure8 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_08.jpg"
import MeydanAvenuePlotBBrochure9 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_09.jpg"
import MeydanAvenuePlotBBrochure10 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_10.jpg"
import MeydanAvenuePlotBBrochure11 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_11.jpg"
import MeydanAvenuePlotBBrochure12 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_12.jpg"
import MeydanAvenuePlotBBrochure13 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_13.jpg"
import MeydanAvenuePlotBBrochure14 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_14.jpg"
import MeydanAvenuePlotBBrochure15 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_15.jpg"

import MeydanAvenuePlotBPlotDesign1 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_01.jpg"
import MeydanAvenuePlotBPlotDesign2 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_02.jpg"
import MeydanAvenuePlotBPlotDesign3 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_03.jpg"
import MeydanAvenuePlotBPlotDesign4 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_04.jpg"
import MeydanAvenuePlotBPlotDesign5 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_05.jpg"
import MeydanAvenuePlotBPlotDesign6 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_06.jpg"
import MeydanAvenuePlotBPlotDesign7 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_07.jpg"
import MeydanAvenuePlotBPlotDesign8 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_08.jpg"
import MeydanAvenuePlotBPlotDesign9 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_09.jpg"
import MeydanAvenuePlotBPlotDesign10 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_10.jpg"
import MeydanAvenuePlotBPlotDesign11 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_11.jpg"
import MeydanAvenuePlotBPlotDesign12 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_12.jpg"
import MeydanAvenuePlotBPlotDesign13 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_13.jpg"
import MeydanAvenuePlotBPlotDesign14 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_14.jpg"
import MeydanAvenuePlotBPlotDesign15 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_15.jpg"

import MeydanAvenuePlotBDevControl1 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_01.jpg"
import MeydanAvenuePlotBDevControl2 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_02.jpg"
import MeydanAvenuePlotBDevControl3 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_03.jpg"
import MeydanAvenuePlotBDevControl4 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_04.jpg"
import MeydanAvenuePlotBDevControl5 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_05.jpg"
import MeydanAvenuePlotBDevControl6 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_06.jpg"
import MeydanAvenuePlotBDevControl7 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_07.jpg"
import MeydanAvenuePlotBDevControl8 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_08.jpg"
import MeydanAvenuePlotBDevControl9 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_09.jpg"
import MeydanAvenuePlotBDevControl10 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_10.jpg"
import MeydanAvenuePlotBDevControl11 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_11.jpg"
import MeydanAvenuePlotBDevControl12 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_12.jpg"
import MeydanAvenuePlotBDevControl13 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_13.jpg"
import MeydanAvenuePlotBDevControl14 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_14.jpg"
import MeydanAvenuePlotBDevControl15 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_15.jpg"

import MeydanAvenuePlotCBrochure1 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_01.jpg"
import MeydanAvenuePlotCBrochure2 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_02.jpg"
import MeydanAvenuePlotCBrochure3 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_03.jpg"
import MeydanAvenuePlotCBrochure4 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_04.jpg"
import MeydanAvenuePlotCBrochure5 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_05.jpg"
import MeydanAvenuePlotCBrochure6 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_06.jpg"
import MeydanAvenuePlotCBrochure7 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_07.jpg"
import MeydanAvenuePlotCBrochure8 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_08.jpg"
import MeydanAvenuePlotCBrochure9 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_09.jpg"
import MeydanAvenuePlotCBrochure10 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_10.jpg"
import MeydanAvenuePlotCBrochure11 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_11.jpg"
import MeydanAvenuePlotCBrochure12 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_12.jpg"
import MeydanAvenuePlotCBrochure13 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_13.jpg"
import MeydanAvenuePlotCBrochure14 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_14.jpg"
import MeydanAvenuePlotCBrochure15 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_15.jpg"

import MeydanAvenuePlotCPlotDesign1 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_01.jpg"
import MeydanAvenuePlotCPlotDesign2 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_02.jpg"
import MeydanAvenuePlotCPlotDesign3 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_03.jpg"
import MeydanAvenuePlotCPlotDesign4 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_04.jpg"
import MeydanAvenuePlotCPlotDesign5 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_05.jpg"
import MeydanAvenuePlotCPlotDesign6 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_06.jpg"
import MeydanAvenuePlotCPlotDesign7 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_07.jpg"
import MeydanAvenuePlotCPlotDesign8 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_08.jpg"
import MeydanAvenuePlotCPlotDesign9 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_09.jpg"
import MeydanAvenuePlotCPlotDesign10 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_10.jpg"
import MeydanAvenuePlotCPlotDesign11 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_11.jpg"
import MeydanAvenuePlotCPlotDesign12 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_12.jpg"
import MeydanAvenuePlotCPlotDesign13 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_13.jpg"
import MeydanAvenuePlotCPlotDesign14 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_14.jpg"
import MeydanAvenuePlotCPlotDesign15 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_15.jpg"

import MeydanAvenuePlotCDevControl1 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_01.jpg"
import MeydanAvenuePlotCDevControl2 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_02.jpg"
import MeydanAvenuePlotCDevControl3 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_03.jpg"
import MeydanAvenuePlotCDevControl4 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_04.jpg"
import MeydanAvenuePlotCDevControl5 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_05.jpg"
import MeydanAvenuePlotCDevControl6 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_06.jpg"
import MeydanAvenuePlotCDevControl7 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_07.jpg"
import MeydanAvenuePlotCDevControl8 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_08.jpg"
import MeydanAvenuePlotCDevControl9 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_09.jpg"
import MeydanAvenuePlotCDevControl10 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_10.jpg"
import MeydanAvenuePlotCDevControl11 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_11.jpg"
import MeydanAvenuePlotCDevControl12 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_12.jpg"
import MeydanAvenuePlotCDevControl13 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_13.jpg"
import MeydanAvenuePlotCDevControl14 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_14.jpg"
import MeydanAvenuePlotCDevControl15 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_15.jpg"


import MarkGolfCourse from '../../../static/images/markers/golf-course/golf-course.svg'
import MarkMeydanHotel from '../../../static/images/markers/meydan-hotel/meydan-hotel.svg'
import MarkPoloResidence from '../../../static/images/markers/polo-residence/polo-residence.svg'


import MarkDistrict11 from '../../../static/images/markers/district-11/district-11.svg'
import District11Preview1 from '../../../static/images/markers/district-11/Renders/Exterior Renders/4_bed.jpg'
import District11Preview2 from '../../../static/images/markers/district-11/Renders/Exterior Renders/4bed_night.jpg'
import District11Preview3 from '../../../static/images/markers/district-11/Renders/Exterior Renders/5_bed.jpg'
import District11Preview4 from '../../../static/images/markers/district-11/Renders/Interior Renders/townhouse_bathroom.jpg'
import District11Preview5 from '../../../static/images/markers/district-11/Renders/Interior Renders/townhouse_livingroom-1.jpg'
import District11Preview6 from '../../../static/images/markers/district-11/Renders/Interior Renders/villa_bathroom-2.jpg'

import District11Brochure1 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_01.jpg"
import District11Brochure2 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_02.jpg"
import District11Brochure3 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_03.jpg"
import District11Brochure4 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_04.jpg"
import District11Brochure5 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_05.jpg"
import District11Brochure6 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_06.jpg"
import District11Brochure7 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_07.jpg"
import District11Brochure8 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_08.jpg"
import District11Brochure9 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_09.jpg"
import District11Brochure10 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_10.jpg"
import District11Brochure11 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_11.jpg"
import District11Brochure12 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_12.jpg"
import District11Brochure13 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_13.jpg"
import District11Brochure14 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_14.jpg"
import District11Brochure15 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_15.jpg"

import District11Factsheet1 from "../../../static/images/files/District 11/c. Factsheet/Factsheet_Page_1.jpg"
import District11Factsheet2 from "../../../static/images/files/District 11/c. Factsheet/Factsheet_Page_2.jpg"
import District11Factsheet3 from "../../../static/images/files/District 11/c. Factsheet/Factsheet_Page_3.jpg"
import District11Factsheet4 from "../../../static/images/files/District 11/c. Factsheet/Factsheet_Page_4.jpg"

import District11FloorPlans1 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_08.jpg"
import District11FloorPlans2 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_09.jpg"
import District11FloorPlans3 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_14.jpg"
import District11FloorPlans4 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_15.jpg"
import District11FloorPlans5 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_16.jpg"
import District11FloorPlans6 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_20.jpg"
import District11FloorPlans7 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_21.jpg"
import District11FloorPlans8 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_24.jpg"
import District11FloorPlans9 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_25.jpg"
import District11FloorPlans10 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_26.jpg"
import District11FloorPlans11 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_27.jpg"


import MarkAirport from '../../../static/images/landmarks/mark-airport.svg'
import MarkDubaiCanal from '../../../static/images/landmarks/mark-dubai-canal.svg'
import MarkJumeirahBeach from '../../../static/images/landmarks/mark-jumeirah-beach.svg'
import MarkNakheelSales from '../../../static/images/landmarks/mark-nakheel-sales.svg'
import MarkPalmMonorail from '../../../static/images/landmarks/mark-palm-monorail.svg'
import MarkNakheelMarinas from '../../../static/images/landmarks/mark-nakheel-marinas.svg'
import MarkTheViewAtThePalm from '../../../static/images/landmarks/mark-the-view-at-the-palm.svg'
import MarkAirportDWC from '../../../static/images/landmarks/mark-airport-dwc.svg'

import MarkBus1 from '../../../static/images/mark-bus-1.svg'
import MarkBus2 from '../../../static/images/mark-bus-2.svg'
import MarkBus3 from '../../../static/images/mark-bus-3.svg'




import { 
    // Infrastructure
    schools, 
    hospitals, 
    retails, 

    // Polygons
    dubai_islands, 
    palm_jumeirah_1, 
    palm_jumeirah_2,
    palm_jebel_ali_1,
    palm_jebel_ali_2,
    palm_jebel_ali_3,
    the_gardens,
    jumeirah_village_circle,
    jumeirah_village_triangle,
    canal_front_residences,
    district_one,
    district_11,

    // Polylines
    bus_road_1,
    bus_road_2,
    bus_road_3,
    bus_road_1_stops,
    bus_road_2_stops,
    bus_road_3_stops,
    jumeirah_heights,
    international_city_1,
    international_city_2,
    the_world_pol_1,
    the_world_pol_2,
    the_world_pol_3,
    the_world_pol_4,
    the_world_pol_5,
    the_world_pol_6,
    the_world_pol_7,
    the_world_pol_8,
    the_world_pol_9,
    the_world_pol_10,
    the_world_pol_11,
    the_world_pol_12,
    the_world_pol_13,
    the_world_pol_14,
    the_world_pol_15,
    the_world_pol_16,
    the_world_pol_17,
    the_world_pol_18,
    the_world_pol_19,
    the_world_pol_20,
    the_world_pol_21,
    the_world_pol_22,
    the_world_pol_23,
    the_world_pol_24,
    the_world_pol_25,
    the_world_pol_26,
    the_world_pol_27,
    the_world_pol_28,
    the_world_pol_29,
    the_world_pol_30,
    the_world_pol_31,
    the_world_pol_32,
    the_world_pol_33,
    the_world_pol_34,
    the_world_pol_35,
    the_world_pol_36,
    the_world_pol_37,
    the_world_pol_38,
    the_world_pol_39,
    the_world_pol_40,
    the_world_pol_41,
    the_world_pol_42,
    the_world_pol_43,
    the_world_pol_44,
    the_world_pol_45,
    the_world_pol_46,
    the_world_pol_47,
    the_world_pol_48,
    the_world_pol_49,
    the_world_pol_50,
    the_world_pol_51,
    the_world_pol_52,
    the_world_pol_53,
    the_world_pol_54,
    the_world_pol_55,
    the_world_pol_56,
    the_world_pol_57,
    the_world_pol_58,
    the_world_pol_59,
    the_world_pol_60,
    the_world_pol_61,
    the_world_pol_62,
    the_world_pol_63,
    the_world_pol_64,
    the_world_pol_65,
    the_world_pol_66,
    the_world_pol_67,
    the_world_pol_68,
    the_world_pol_69,
    the_world_pol_70,
    the_world_pol_71,
    the_world_pol_72,
    the_world_pol_73,
    the_world_pol_74,
    the_world_pol_75,
    the_world_pol_76,
    the_world_pol_77,
    the_world_pol_78,
    the_world_pol_79,
    the_world_pol_80,
    the_world_pol_81,
    the_world_pol_82,
    the_world_pol_83,
    the_world_pol_84,
    the_world_pol_85,
    the_world_pol_86,
    the_world_pol_87,
    the_world_pol_88,
    the_world_pol_89,
    the_world_pol_90,
    the_world_pol_91,
    the_world_pol_92,
    the_world_pol_93,
    the_world_pol_94,
    the_world_pol_95,
    the_world_pol_96,
    the_world_pol_97,
    the_world_pol_98,
    the_world_pol_99,
    the_world_pol_100,
    the_world_pol_101,
    the_world_pol_102,
    the_world_pol_103,
    the_world_pol_104,
    the_world_pol_105,
    the_world_pol_106,
    the_world_pol_107,
    the_world_pol_108,
    the_world_pol_109,
    the_world_pol_110,
    the_world_pol_111,
    the_world_pol_112,
    the_world_pol_113,
    the_world_pol_114,
    the_world_pol_115,
    the_world_pol_116,
    the_world_pol_117,
    the_world_pol_118,
    the_world_pol_119,
    the_world_pol_120,
    the_world_pol_121,
    the_world_pol_122,
    the_world_pol_123,
    the_world_pol_124,
    the_world_pol_125,
    the_world_pol_126,
    the_world_pol_127,
    the_world_pol_128,
    the_world_pol_129,
    the_world_pol_130,
    the_world_pol_131,
    the_world_pol_132,
    the_world_pol_133,
    the_world_pol_134,
    the_world_pol_135,
    the_world_pol_136,
    the_world_pol_137,
    the_world_pol_138,
    the_world_pol_139,
    the_world_pol_140,
    the_world_pol_141,
    the_world_pol_142,
    the_world_pol_143,
    the_world_pol_144,
    the_world_pol_145,
    the_world_pol_146,
    the_world_pol_147,
    the_world_pol_148,
    the_world_pol_149,
    the_world_pol_150,
    the_world_pol_151,
    the_world_pol_152,
    the_world_pol_153,
    the_world_pol_154,
    the_world_pol_155,
    the_world_pol_156,
    the_world_pol_157,
    the_world_pol_158,
    the_world_pol_159,
    the_world_pol_160,
    the_world_pol_161,
    the_world_pol_162,
    the_world_pol_163,
    the_world_pol_164,
    the_world_pol_165,
    the_world_pol_166,
    the_world_pol_167,
    the_world_pol_168,
    the_world_pol_169,
    the_world_pol_170,
    the_world_pol_171,
    the_world_pol_172,
    the_world_pol_173,
    the_world_pol_174,
    the_world_pol_175,
    the_world_pol_176,
    the_world_pol_177,
    the_world_pol_178,
    the_world_pol_179,
    the_world_pol_180,
    the_world_pol_181,
    the_world_pol_182,
    the_world_pol_183,
    the_world_pol_184,
    the_world_pol_185,
    the_world_pol_186,
    the_world_pol_187,
    the_world_pol_188,
    the_world_pol_189,
    the_world_pol_190,
    the_world_pol_191,
    the_world_pol_192,
    the_world_pol_193,
    the_world_pol_194,
    the_world_pol_195,
    the_world_pol_196,
    the_world_pol_197,
    the_world_pol_198,
    the_world_pol_199,
    the_world_pol_200,
    the_world_pol_201,
    the_world_pol_202,
    the_world_pol_203,    
    the_world_pol_204,
    bus_road_1_2
} from "./Coords";


export const infrastrMarkers = [
    {
        id: 'health',
        allCoords: hospitals,
        icon: MarkHospital
    },
    {
        id: 'education',
        allCoords: schools,
        icon: MarkSchool
    },
    {
        id: 'retail_or_shopping',
        allCoords: retails,
        icon: MarkShopping
    },
]


export const markers = [
    {
        id: 'the_gardens',
        checkIsInGroup: true,
        groupName: ['nakheel', 'plots'],
        zoomLevel: null,
        coords: {lat: 25.0423900606047, lng: 55.1279246642445},
        polygonCoords: [the_gardens],
        icon: MarkTheGardens,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'palm_jebel_ali',
        checkIsInGroup: true,
        groupName: ['nakheel', 'plots', 'villas'],
        zoomLevel: null,
        coords: {lat: 24.9875968817914, lng: 55.0025720069105},
        polygonCoords: [palm_jebel_ali_1, palm_jebel_ali_2, palm_jebel_ali_3],
        icon: MarkPalmJebelAli,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null,
        // content: {
        //     title: '杰贝阿里棕榈岛',
        //     previews: [
        //         PalmJebelAliPreview1,
        //         PalmJebelAliPreview2,
        //         PalmJebelAliPreview3,
        //         PalmJebelAliPreview4,
        //         PalmJebelAliPreview5,
        //         PalmJebelAliPreview6,
        //         PalmJebelAliPreview7,
        //         PalmJebelAliPreview8,
        //         PalmJebelAliPreview9,
        //     ],
        //     desc: '',
        //     link: null,
        //     video: null,
        //     buttons: [
        //         {
        //             type: "double",
        //             data: [
        //                 {
        //                     type: 'img',
        //                     text: "指引",
        //                     data: [{
        //                         file: [
        //                             PalmJebelAliGuidelines1,
        //                             PalmJebelAliGuidelines2,
        //                             PalmJebelAliGuidelines3,
        //                             PalmJebelAliGuidelines4,
        //                             PalmJebelAliGuidelines5,
        //                             PalmJebelAliGuidelines6,
        //                             PalmJebelAliGuidelines7,
        //                             PalmJebelAliGuidelines8,
        //                             PalmJebelAliGuidelines9,
        //                             PalmJebelAliGuidelines10,
        //                             PalmJebelAliGuidelines11,
        //                             PalmJebelAliGuidelines12,
        //                             PalmJebelAliGuidelines13,
        //                             PalmJebelAliGuidelines14,
        //                             PalmJebelAliGuidelines15
        //                         ]
        //                     }]
        //                 },
        //                 {
        //                     type: 'img',
        //                     text: "地皮",
        //                     data: [
        //                         {
        //                             title: "PLC",
        //                             file: [
        //                                 PalmJebelAliPlotsPLC_1,
        //                                 PalmJebelAliPlotsPLC_2,
        //                                 PalmJebelAliPlotsPLC_3,
        //                                 PalmJebelAliPlotsPLC_4,
        //                                 PalmJebelAliPlotsPLC_5,
        //                                 PalmJebelAliPlotsPLC_6,
        //                                 PalmJebelAliPlotsPLC_7,
        //                                 PalmJebelAliPlotsPLC_8,
        //                                 PalmJebelAliPlotsPLC_9,
        //                                 PalmJebelAliPlotsPLC_10,
        //                                 PalmJebelAliPlotsPLC_11,
        //                                 PalmJebelAliPlotsPLC_12,
        //                                 PalmJebelAliPlotsPLC_13,
        //                                 PalmJebelAliPlotsPLC_14,
        //                                 PalmJebelAliPlotsPLC_15
        //                             ]
        //                         },
        //                         {
        //                             title: "开发控制条例",
        //                             file: [
        //                                 PalmJebelAliPlotsDCR_1,
        //                                 PalmJebelAliPlotsDCR_2,
        //                                 PalmJebelAliPlotsDCR_3,
        //                                 PalmJebelAliPlotsDCR_4,
        //                                 PalmJebelAliPlotsDCR_5,
        //                                 PalmJebelAliPlotsDCR_6,
        //                                 PalmJebelAliPlotsDCR_7,
        //                                 PalmJebelAliPlotsDCR_8,
        //                                 PalmJebelAliPlotsDCR_9,
        //                                 PalmJebelAliPlotsDCR_10,
        //                                 PalmJebelAliPlotsDCR_11,
        //                                 PalmJebelAliPlotsDCR_12,
        //                                 PalmJebelAliPlotsDCR_13,
        //                                 PalmJebelAliPlotsDCR_14,
        //                                 PalmJebelAliPlotsDCR_15
        //                             ]
        //                         }
        //                     ]
        //                 }
        //             ]
        //         },
        //         {
        //             type: "double",
        //             data: [
        //                 {
        //                     type: 'img',
        //                     text: "材料版楼书",
        //                     data: [{
        //                         file: [
        //                             PalmJebelAliMaterialBoardBrochure1,
        //                             PalmJebelAliMaterialBoardBrochure2,
        //                             PalmJebelAliMaterialBoardBrochure3,
        //                             PalmJebelAliMaterialBoardBrochure4,
        //                             PalmJebelAliMaterialBoardBrochure5,
        //                             PalmJebelAliMaterialBoardBrochure6,
        //                             PalmJebelAliMaterialBoardBrochure7,
        //                             PalmJebelAliMaterialBoardBrochure8,
        //                             PalmJebelAliMaterialBoardBrochure9,
        //                             PalmJebelAliMaterialBoardBrochure10,
        //                             PalmJebelAliMaterialBoardBrochure11,
        //                             PalmJebelAliMaterialBoardBrochure12,
        //                             PalmJebelAliMaterialBoardBrochure13,
        //                             PalmJebelAliMaterialBoardBrochure14,
        //                             PalmJebelAliMaterialBoardBrochure15
        //                         ]
        //                     }]
        //                 },
        //                 {
        //                     type: 'img',
        //                     text: "楼书",
        //                     data: [{
        //                         file: [
        //                             PalmJebelAliBrochure1,
        //                             PalmJebelAliBrochure2,
        //                             PalmJebelAliBrochure3,
        //                             PalmJebelAliBrochure4,
        //                             PalmJebelAliBrochure5,
        //                             PalmJebelAliBrochure6,
        //                             PalmJebelAliBrochure7,
        //                             PalmJebelAliBrochure8,
        //                             PalmJebelAliBrochure9,
        //                             PalmJebelAliBrochure10,
        //                             PalmJebelAliBrochure11,
        //                             PalmJebelAliBrochure12,
        //                             PalmJebelAliBrochure13,
        //                             PalmJebelAliBrochure14,
        //                             PalmJebelAliBrochure15
        //                         ]
        //                     }]
        //                 }
        //             ]
        //         },
        //         {
        //             type: "double",
        //             data: [
        //                 {
        //                     type: 'img',
        //                     text: "楼层规划图",
        //                     data: [
        //                         {
        //                             title: "UKL",
        //                             file: [
        //                                 PalmJebelAliFloorPlansUKL1,
        //                                 PalmJebelAliFloorPlansUKL2,
        //                                 PalmJebelAliFloorPlansUKL3,
        //                                 PalmJebelAliFloorPlansUKL4,
        //                                 PalmJebelAliFloorPlansUKL5,
        //                                 PalmJebelAliFloorPlansUKL6
        //                             ]
        //                         },
        //                         {
        //                             title: "MNOP",
        //                             file: [
        //                                 PalmJebelAliFloorPlansMNOP1,
        //                                 PalmJebelAliFloorPlansMNOP2,
        //                                 PalmJebelAliFloorPlansMNOP3
        //                             ]
        //                         },
        //                     ]
        //                 },
        //                 {
        //                     type: 'img',
        //                     text: "整体规划",
        //                     data: [{
        //                         file: [
        //                             PalmJebelAliMasterplan1,
        //                             PalmJebelAliMasterplan2
        //                         ]
        //                     }]
        //                 }
        //             ]
        //         },
        //     ]
        // }
    },
    {
        id: 'palm_jumeirah',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.1223564585631, lng: 55.1319115423103},
        polygonCoords: [palm_jumeirah_1, palm_jumeirah_2],
        icon: MarkPalmJumeirah,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'veneto',
        checkIsInGroup: true,
        groupName: ['nakheel', 'plots'],
        zoomLevel: null,
        coords: {lat: 24.9548784129207, lng: 54.9978584756706},
        polygonCoords: null,
        icon: MarkVeneto,
        activeIcon: MarkVenetoBlack,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'dubai_islands',
        checkIsInGroup: true,
        groupName: ['nakheel', 'plots_villas_apartments'],
        zoomLevel: null,
        coords: {lat: 25.3208847325933, lng: 55.3034074496439},
        polygonCoords: [dubai_islands],
        icon: MarkDubaiIslands,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null,
        // content: {
        //     title: '迪拜群岛',
        //     previews: [
        //         DubaiIslandsPreview1,
        //         DubaiIslandsPreview2
        //     ],
        //     desc: '',
        //     link: 'https://www.canalfrontresidences.meydan.ae/',
        //     video: DubaiIslandsVideo,
        //     buttons: [
        //         {
        //             type: "double",
        //             data: [
        //                 {
        //                     type: 'img',
        //                     text: "楼书",
        //                     data: [{
        //                         file: [
        //                             DubaiIslandsBrochure1,
        //                             DubaiIslandsBrochure2,
        //                             DubaiIslandsBrochure3,
        //                             DubaiIslandsBrochure4,
        //                             DubaiIslandsBrochure5,
        //                             DubaiIslandsBrochure6,
        //                             DubaiIslandsBrochure7,
        //                             DubaiIslandsBrochure8,
        //                             DubaiIslandsBrochure9,
        //                             DubaiIslandsBrochure10,
        //                             DubaiIslandsBrochure11,
        //                             DubaiIslandsBrochure12,
        //                             DubaiIslandsBrochure13,
        //                             DubaiIslandsBrochure14,
        //                             DubaiIslandsBrochure15
        //                         ]
        //                     }]
        //                 },
        //                 {
        //                     type: 'img',
        //                     text: "迪拜群岛A岛",
        //                     data: [{
        //                         file: [
        //                             DubaiIslandsMasterplan1,
        //                             DubaiIslandsMasterplan2
        //                         ]
        //                     }]
        //                 }
        //             ]
        //         }
        //     ]
        // }
    },
    {
        id: 'jebel_ali_village',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: 11.5,
        coords: {lat: 25.0298263289557, lng: 55.1313941679255},
        polygonCoords: [],
        icon: MarkJebelAliVillage,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null,
        // content: {
        //     title: '杰贝阿里村',
        //     previews: [
        //         JebelAliVillagePreview1,
        //         JebelAliVillagePreview3,
        //         JebelAliVillagePreview4,
        //         JebelAliVillagePreview5,
        //         JebelAliVillagePreview6,
        //         JebelAliVillagePreview7,
        //         JebelAliVillagePreview8,
        //         JebelAliVillagePreview9,
        //     ],
        //     desc: '',
        //     video: JebelAliVillageVideo,
        //     buttons: [
        //         {
        //             type: "double",
        //             data: [
        //                 {
        //                     type: 'img',
        //                     text: "楼书",
        //                     data: [{
        //                         file: [
        //                             JebelAliVillageBrochure1,
        //                             JebelAliVillageBrochure2,
        //                             JebelAliVillageBrochure3,
        //                             JebelAliVillageBrochure4,
        //                             JebelAliVillageBrochure5,
        //                             JebelAliVillageBrochure6,
        //                             JebelAliVillageBrochure7,
        //                             JebelAliVillageBrochure8,
        //                             JebelAliVillageBrochure9,
        //                             JebelAliVillageBrochure10,
        //                             JebelAliVillageBrochure11,
        //                             JebelAliVillageBrochure12,
        //                             JebelAliVillageBrochure13,
        //                             JebelAliVillageBrochure14,
        //                             JebelAliVillageBrochure15
        //                         ]
        //                     }]
        //                 },
        //                 {
        //                     type: 'img',
        //                     text: "资料页",
        //                     data: [{
        //                         file: [
        //                             JebelAliVillageFactSheet1,
        //                             JebelAliVillageFactSheet2
        //                         ]
        //                     }]
        //                 }
        //             ]
        //         },
        //         {
        //             type: "double",
        //             data: [
        //                 {
        //                     type: 'img',
        //                     text: "楼层规划图",
        //                     data: [{
        //                         file: [
        //                             JebelAliVillageFloorPlan1,
        //                             JebelAliVillageFloorPlan2,
        //                             JebelAliVillageFloorPlan3,
        //                             JebelAliVillageFloorPlan4,
        //                             JebelAliVillageFloorPlan5,
        //                             JebelAliVillageFloorPlan6,
        //                             JebelAliVillageFloorPlan7,
        //                             JebelAliVillageFloorPlan8,
        //                             JebelAliVillageFloorPlan9,
        //                             JebelAliVillageFloorPlan10,
        //                             JebelAliVillageFloorPlan11,
        //                             JebelAliVillageFloorPlan12,
        //                             JebelAliVillageFloorPlan13,
        //                             JebelAliVillageFloorPlan14,
        //                             JebelAliVillageFloorPlan15
        //                         ]
        //                     }]
        //                 },
        //                 {
        //                     type: 'img',
        //                     text: "整体规划 A3",
        //                     data: [{file: [JebelAliVillageMasterPlan]}]
        //                 }
        //             ]
        //         },
        //         {
        //             type: "single",
        //             data: [
        //                 {
        //                     type: 'img',
        //                     text: "付款计划",
        //                     data: [{file: [JebelAliVillagePaymentPlan]}]
        //                 },
        //             ]
        //         },
        //     ]
        // }
    },
    {
        id: 'jebel_ali_club',
        checkIsInGroup: true,
        groupName: ['nakheel', 'plots'],
        zoomLevel: 13,
        coords: {lat: 25.0359815063039, lng: 55.115931997769},
        polygonCoords: [],
        icon: MarkJebelAliClub,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'jumeirah_park',
        checkIsInGroup: true,
        groupName: ['nakheel', 'plots'],
        zoomLevel: null,
        coords: {lat: 25.0469980610292, lng: 55.1552081007093},
        polygonCoords: [],
        icon: MarkJumeirahPark,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'jumeirah_heights',
        checkIsInGroup: true,
        groupName: ['nakheel', 'plots'],
        zoomLevel: 12,
        coords: {lat: 25.0667078013068, lng: 55.147858750232},
        polygonCoords: [jumeirah_heights],
        icon: MarkJumeirahHeights,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'jumeirah_islands',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: 11.5,
        coords: {lat: 25.0576104830134, lng: 55.1557827842716},
        polygonCoords: [],
        icon: MarkJumeirahIslands,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'al_furjan',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas', 'new_project'],
        zoomLevel: null,
        coords: {lat: 25.0251434071843, lng: 55.1471077931815},
        polygonCoords: [],
        icon: MarkAlFurjan,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'murooj_al_furjan',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: 12.5,
        coords: {lat: 25.0303480632162, lng: 55.1384807977714},
        polygonCoords: [],
        icon: MarkMuroojAlFurjan,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'tilal_al_furjan',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: 12,
        coords: {lat: 25.0263591519061, lng: 55.1304321969928},
        polygonCoords: [],
        icon: MarkTilalAlFurjan,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null,
        // content: {
            // title: '蒂拉尔·阿尔富尔詹',
            // previews: [
            //     TilalAlFurjanPreview1,
            //     TilalAlFurjanPreview2,
            //     TilalAlFurjanPreview3,
            //     TilalAlFurjanPreview4,
            //     TilalAlFurjanPreview5,
            // ],
            // desc: '',
            // link: 'https://www.tilalalfurjan.ae/',
            // video: TilalAlFurjanVideo,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 type: 'img',
            //                 text: "楼层规划&楼书",
            //                 data: [{
            //                     file: [
            //                         TilalAlFurjanBrochure1,
            //                         TilalAlFurjanBrochure2,
            //                         TilalAlFurjanBrochure3,
            //                         TilalAlFurjanBrochure4,
            //                         TilalAlFurjanBrochure5,
            //                         TilalAlFurjanBrochure6,
            //                         TilalAlFurjanBrochure7,
            //                         TilalAlFurjanBrochure8,
            //                         TilalAlFurjanBrochure9,
            //                         TilalAlFurjanBrochure10,
            //                         TilalAlFurjanBrochure11,
            //                         TilalAlFurjanBrochure12,
            //                         TilalAlFurjanBrochure13,
            //                         TilalAlFurjanBrochure14,
            //                         TilalAlFurjanBrochure15
            //                     ]
            //                 }]
            //             }
            //         ]
            //     },
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 type: 'img',
            //                 text: "资料页",
            //                 data: [{
            //                     file: [
            //                         TilalAlFurjanFactSheet1,
            //                         TilalAlFurjanFactSheet2,
            //                         TilalAlFurjanFactSheet3
            //                     ]
            //                 }]
            //             }
            //         ]
            //     }
            // ]
        // }
    },
    {
        id: 'lagoon-views',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.15731045557504, lng: 55.28462817116014},
        polygonCoords: [],
        icon: LagoonViewsMark,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null,
        // content: {
            // title: '水晶湖景观',
            // previews: [
            //     LagoonViewsPreview1,
            //     LagoonViewsPreview2,
            //     LagoonViewsPreview3,
            //     LagoonViewsPreview4,
            //     LagoonViewsPreview5,
            //     LagoonViewsPreview6,
            // ],
            // desc: '',
            // link: null,
            // video: LagoonViewsVideo,
            // buttons: [
            //     {
            //         type: "double",
            //         data: [
            //             {
            //                 type: 'img',
            //                 text: "整体规划",
            //                 data: [{
            //                     file: [
            //                         LagoonViewsMasterplan
            //                     ]
            //                 }]
            //             },
            //             {
            //                 type: 'img',
            //                 text: "付款计划",
            //                 data: [{
            //                     file: [
            //                         LagoonViewsPaymentPlan
            //                     ]
            //                 }]
            //             }
            //         ]
            //     },
            //     {
            //         type: "double",
            //         data: [
            //             {
            //                 type: 'img',
            //                 text: "楼层规划图",
            //                 data: [{
            //                     file: [
            //                         LagoonViewsFloorplan1,
            //                         LagoonViewsFloorplan2,
            //                         LagoonViewsFloorplan3,
            //                         LagoonViewsFloorplan4,
            //                         LagoonViewsFloorplan5,
            //                         LagoonViewsFloorplan6,
            //                         LagoonViewsFloorplan7,
            //                         LagoonViewsFloorplan8,
            //                         LagoonViewsFloorplan9,
            //                         LagoonViewsFloorplan10,
            //                         LagoonViewsFloorplan11,
            //                         LagoonViewsFloorplan12,
            //                         LagoonViewsFloorplan13,
            //                         LagoonViewsFloorplan14,
            //                         LagoonViewsFloorplan15
            //                     ]
            //                 }]
            //             },
            //             {
            //                 type: 'img',
            //                 text: "楼书",
            //                 data: [{
            //                     file: [
            //                         LagoonViewsBrochure1,
            //                         LagoonViewsBrochure2,
            //                         LagoonViewsBrochure3,
            //                         LagoonViewsBrochure4,
            //                         LagoonViewsBrochure5,
            //                         LagoonViewsBrochure6,
            //                         LagoonViewsBrochure7,
            //                         LagoonViewsBrochure8,
            //                         LagoonViewsBrochure9,
            //                         LagoonViewsBrochure10,
            //                         LagoonViewsBrochure11,
            //                         LagoonViewsBrochure12,
            //                         LagoonViewsBrochure13,
            //                         LagoonViewsBrochure14,
            //                         LagoonViewsBrochure15
            //                     ]
            //                 }]
            //             }
            //         ]
            //     },
            // ]
        // }
    },
    {
        id: 'masakin_al_furjan',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: 12,
        coords: {lat: 25.0303178662483, lng: 55.1492385030902},
        polygonCoords: [],
        icon: MarkMasakinAlFurjan,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'discovery_gardens',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: 12,
        coords: {lat: 25.039989855318, lng: 55.1440769023553},
        polygonCoords: [],
        icon: MarkDiscoveryGardens,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'jumeirah_village_triangle',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: 11.5,
        coords: {lat: 25.0485210715624, lng: 55.1898620162536},
        polygonCoords: [jumeirah_village_triangle],
        icon: MarkJumeirahVillageTriangle,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'jumeirah_village_circle',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: null,
        coords: {lat: 25.0612529333819, lng: 55.2096228990439},
        polygonCoords: [jumeirah_village_circle],
        icon: MarkJumeirahVillageCircle,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'como_residences',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.1110401298578, lng: 55.1458511956965},
        polygonCoords: [],
        icon: MarkComoResidences,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: '科莫大厦',
            previews: [
                ComoResidencesPreview1,
                ComoResidencesPreview2,
                ComoResidencesPreview3,
                ComoResidencesPreview4,
                ComoResidencesPreview5,
                ComoResidencesPreview6,
                ComoResidencesPreview7,
                ComoResidencesPreview8,
                ComoResidencesPreview9,
                ComoResidencesPreview10,
            ],
            desc: '作为著名的科莫酒店及度假村品牌的一部分，科莫公寓拥有无与伦比的设施和服务，为居民提供世界一流的生活体验。令人惊叹的高层塔楼享有迪拜天际线的壮丽景色。',
            video: VideoComoResidences,
            link: 'https://www.comobynakheel.com/',
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "楼书",
                            data: [{
                                file: [
                                    ComoResidencesBrochure1,
                                    ComoResidencesBrochure2,
                                    ComoResidencesBrochure3,
                                    ComoResidencesBrochure4,
                                    ComoResidencesBrochure5,
                                    ComoResidencesBrochure6,
                                    ComoResidencesBrochure7,
                                    ComoResidencesBrochure8,
                                    ComoResidencesBrochure9,
                                    ComoResidencesBrochure10,
                                    ComoResidencesBrochure11,
                                    ComoResidencesBrochure12,
                                    ComoResidencesBrochure13,
                                    ComoResidencesBrochure14,
                                    ComoResidencesBrochure15
                                ]
                            }]
                        },
                        {
                            type: 'img',
                            text: "楼层规划图",
                            data: [{
                                file: [
                                    ComoResidencesFloorPlan1,
                                    ComoResidencesFloorPlan2,
                                    ComoResidencesFloorPlan3,
                                    ComoResidencesFloorPlan4,
                                    ComoResidencesFloorPlan5,
                                    ComoResidencesFloorPlan6,
                                    ComoResidencesFloorPlan7,
                                    ComoResidencesFloorPlan8,
                                    ComoResidencesFloorPlan9,
                                    ComoResidencesFloorPlan10,
                                    ComoResidencesFloorPlan11,
                                    ComoResidencesFloorPlan12,
                                    ComoResidencesFloorPlan13,
                                    ComoResidencesFloorPlan14,
                                    ComoResidencesFloorPlan15
                                ]
                            }]
                        }
                    ]
                },
            ]
        }
    },
    {
        id: 'greenwood',
        checkIsInGroup: true,
        groupName: ['nakheel', 'plots'],
        zoomLevel: null,
        coords: {lat: 25.14225, lng: 55.406722},
        polygonCoords: [],
        icon: GreenwoodMark,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: '格林伍德社区',
            previews: [
                GreenwoodPreview1,
                GreenwoodPreview2,
                GreenwoodPreview3,
                GreenwoodPreview4,
                GreenwoodPreview5,
                GreenwoodPreview6,
                GreenwoodPreview7,
                GreenwoodPreview8,
                GreenwoodPreview9,
            ],
            desc: '迪拜的格林伍德是一个富有远见的城市开发项目，将创新、可持续性和奢华融入这座充满活力的城市的中心。这片广阔的绿洲经过精心设计，与周围的沙漠景观相协调，通过融合尖端建筑、绿色空间和最先进的设施，重新定义了现代生活。',
            video: GreenwoodVideo,
            buttons: [
                {
                    type: "single",
                    data: [
                        {
                            type: 'img',
                            text: "楼书",
                            data: [{
                                file: [
                                    GreenwoodBrochure1,
                                    GreenwoodBrochure2,
                                    GreenwoodBrochure3,
                                    GreenwoodBrochure4,
                                    GreenwoodBrochure5,
                                    GreenwoodBrochure6,
                                    GreenwoodBrochure7,
                                    GreenwoodBrochure8,
                                    GreenwoodBrochure9,
                                    GreenwoodBrochure10,
                                    GreenwoodBrochure11,
                                    GreenwoodBrochure12,
                                    GreenwoodBrochure13,
                                    GreenwoodBrochure14,
                                    GreenwoodBrochure15
                                ]
                            }]
                        },
                    ]
                }
            ]
        }
    },
    {
        id: 'rixos',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments_villas'],
        zoomLevel: null,
        coords: {lat: 25.30139936, lng: 55.31203838},
        polygonCoords: [],
        icon: RixosMark,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: '五星级度假酒店RIXOS联名的酒店&住宅',
            previews: [
                Rixos1,
                Rixos2,
                Rixos3,
                Rixos4,
                Rixos5,
                Rixos6,
                Rixos7,
                Rixos8,
                Rixos9,
                Rixos10,
            ],
            desc: 'Rixos Dubai Islands Hotel & Residences是一个高级开发项目，在迪拜群岛提供海滨住宅。提供的住宅包括 1-3 居室公寓、2-3 居室海滨别墅和少量的独栋别墅。Rixos Dubai Islands Hotel & Residences by Nakheel的住宅设施，核心和点单服务将让您进入度假胜地般的生活。',
            link: 'https://rixos-landing.webflow.io/emotions/main-page',
            video: RixosVideo,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "楼书",
                            data: [
                                {file: [
                                    RixosBrochure1,
                                    RixosBrochure2,
                                    RixosBrochure3,
                                    RixosBrochure4,
                                    RixosBrochure5,
                                    RixosBrochure6,
                                    RixosBrochure7,
                                    RixosBrochure8,
                                    RixosBrochure9,
                                    RixosBrochure10,
                                    RixosBrochure11,
                                    RixosBrochure12,
                                    RixosBrochure13,
                                    RixosBrochure14,
                                    RixosBrochure15
                                ]}
                            ]
                        },
                        {
                            type: 'img',
                            text: "楼层规划图",
                            data: [
                                {
                                    title: "B2",
                                    file: [
                                        RixosFloorPlansB2_1,
                                        RixosFloorPlansB2_2,
                                        RixosFloorPlansB2_3,
                                        RixosFloorPlansB2_4,
                                        RixosFloorPlansB2_5,
                                        RixosFloorPlansB2_6,
                                        RixosFloorPlansB2_7,
                                        RixosFloorPlansB2_8,
                                        RixosFloorPlansB2_9,
                                        RixosFloorPlansB2_10,
                                        RixosFloorPlansB2_11,
                                        RixosFloorPlansB2_12,
                                        RixosFloorPlansB2_13,
                                        RixosFloorPlansB2_14,
                                        RixosFloorPlansB2_15
                                    ]
                                },
                                {
                                    title: "B3",
                                    file: [
                                        RixosFloorPlansB3_1,
                                        RixosFloorPlansB3_2,
                                        RixosFloorPlansB3_3,
                                        RixosFloorPlansB3_4,
                                        RixosFloorPlansB3_5,
                                        RixosFloorPlansB3_6,
                                        RixosFloorPlansB3_7,
                                        RixosFloorPlansB3_8,
                                        RixosFloorPlansB3_9,
                                        RixosFloorPlansB3_10,
                                        RixosFloorPlansB3_11,
                                        RixosFloorPlansB3_12,
                                        RixosFloorPlansB3_13,
                                        RixosFloorPlansB3_14,
                                        RixosFloorPlansB3_15
                                    ]
                                },
                                {
                                    title: "B4",
                                    file: [
                                        RixosFloorPlansB4_1,
                                        RixosFloorPlansB4_2,
                                        RixosFloorPlansB4_3,
                                        RixosFloorPlansB4_4,
                                        RixosFloorPlansB4_5,
                                        RixosFloorPlansB4_6,
                                        RixosFloorPlansB4_7,
                                        RixosFloorPlansB4_8,
                                        RixosFloorPlansB4_9,
                                        RixosFloorPlansB4_10,
                                        RixosFloorPlansB4_11,
                                        RixosFloorPlansB4_12,
                                        RixosFloorPlansB4_13,
                                        RixosFloorPlansB4_14,
                                        RixosFloorPlansB4_15
                                    ]
                                },
                                {
                                    title: "P2",
                                    file: [
                                        RixosFloorPlansP2_1,
                                        RixosFloorPlansP2_2,
                                        RixosFloorPlansP2_3,
                                        RixosFloorPlansP2_4,
                                        RixosFloorPlansP2_5,
                                        RixosFloorPlansP2_6,
                                        RixosFloorPlansP2_7,
                                        RixosFloorPlansP2_8,
                                        RixosFloorPlansP2_9,
                                        RixosFloorPlansP2_10,
                                        RixosFloorPlansP2_11,
                                        RixosFloorPlansP2_12,
                                        RixosFloorPlansP2_13,
                                        RixosFloorPlansP2_14,
                                        RixosFloorPlansP2_15
                                    ]
                                },
                                {
                                    title: "Beach Houses",
                                    file: [
                                        RixosFloorPlansBeachHouses1,
                                        RixosFloorPlansBeachHouses2,
                                        RixosFloorPlansBeachHouses3,
                                        RixosFloorPlansBeachHouses4,
                                        RixosFloorPlansBeachHouses5,
                                        RixosFloorPlansBeachHouses6,
                                        RixosFloorPlansBeachHouses7,
                                        RixosFloorPlansBeachHouses8,
                                        RixosFloorPlansBeachHouses9,
                                        RixosFloorPlansBeachHouses10,
                                        RixosFloorPlansBeachHouses11,
                                        RixosFloorPlansBeachHouses12,
                                        RixosFloorPlansBeachHouses13,
                                        RixosFloorPlansBeachHouses14,
                                        RixosFloorPlansBeachHouses15
                                    ]
                                },
                            ]
                        }
                    ]
                }
            ]
        }
    },
    {
        id: 'district-one-west-2',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: null,
        coords: {lat: 25.14489796, lng: 55.27764592},
        polygonCoords: [],
        icon: DistrictOneMark,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null,
        // content: {
            // title: '美丹一区西区',
            // previews: [
            //     DistrictOneWestPreview1,
            //     DistrictOneWestPreview2,
            //     DistrictOneWestPreview5,
            //     DistrictOneWestPreview6
            // ],
            // desc: 'Nakheel 展示了迪拜 美丹酋长城一区西区的史诗般的生活方式，以激发一个新的发展时代，提供 4 居室和 5 居室别墅以及 6 居室和 7 居室豪宅。快来在迪拜最负盛名的社区之一中亲自探索最高级的奢华水平。在一个崇高的封闭式社区中体验非凡的生活方式，为居民提供无可挑剔的房产和优质住宅。',
            // video: DistrictOneVideo,
            // buttons: [
            //     {
            //         type: "double",
            //         data: [
            //             {
            //                 type: 'img',
            //                 text: "楼书",
            //                 data: [{
            //                     file: [
            //                         DistrictOneBrochure1,
            //                         DistrictOneBrochure2,
            //                         DistrictOneBrochure3,
            //                         DistrictOneBrochure4,
            //                         DistrictOneBrochure5,
            //                         DistrictOneBrochure6,
            //                         DistrictOneBrochure7,
            //                         DistrictOneBrochure8,
            //                         DistrictOneBrochure9,
            //                         DistrictOneBrochure10,
            //                         DistrictOneBrochure11,
            //                         DistrictOneBrochure12,
            //                         DistrictOneBrochure13,
            //                         DistrictOneBrochure14,
            //                         DistrictOneBrochure15
            //                     ]
            //                 }]
            //             },
            //             {
            //                 type: 'img',
            //                 text: "楼层规划图",
            //                 data: [{
            //                     file: [
            //                         DistrictOneFloorPlans1,
            //                         DistrictOneFloorPlans2,
            //                         DistrictOneFloorPlans3,
            //                         DistrictOneFloorPlans4,
            //                         DistrictOneFloorPlans5,
            //                         DistrictOneFloorPlans6,
            //                         DistrictOneFloorPlans7,
            //                         DistrictOneFloorPlans8,
            //                         DistrictOneFloorPlans9,
            //                         DistrictOneFloorPlans10,
            //                         DistrictOneFloorPlans11,
            //                         DistrictOneFloorPlans12,
            //                         DistrictOneFloorPlans13,
            //                         DistrictOneFloorPlans14,
            //                         DistrictOneFloorPlans15
            //                     ]
            //                 }]
            //             }
            //         ]
            //     },
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 type: 'img',
            //                 text: "整体规划",
            //                 data: [{file: [DistrictOneMasterplan]}]
            //             }
            //         ]
            //     },
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 type: 'img',
            //                 text: "材料版楼书",
            //                 data: [{
            //                     file: [
            //                         DistrictOneMaterialBoardBrochure1,
            //                         DistrictOneMaterialBoardBrochure2,
            //                         DistrictOneMaterialBoardBrochure3,
            //                         DistrictOneMaterialBoardBrochure4,
            //                         DistrictOneMaterialBoardBrochure5,
            //                         DistrictOneMaterialBoardBrochure6,
            //                         DistrictOneMaterialBoardBrochure7,
            //                         DistrictOneMaterialBoardBrochure8,
            //                         DistrictOneMaterialBoardBrochure9,
            //                         DistrictOneMaterialBoardBrochure10,
            //                         DistrictOneMaterialBoardBrochure11,
            //                         DistrictOneMaterialBoardBrochure12
            //                     ]
            //                 }]
            //             }
            //         ]
            //     }
            // ]
        // }
    },
    {
        id: 'the_palm_tower_residences',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: 12,
        coords: {lat: 25.1149504481807, lng: 55.13787513591},
        polygonCoords: [],
        icon: MarkThePalmTowerResidences,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'st_regis_dubai',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: 12.5,
        coords: {lat: 25.1131661007271, lng: 55.1418165472705},
        polygonCoords: [],
        icon: MarkStRegisDubaiThePalm,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'palm_beach_towers',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: 12,
        coords: {lat: 25.1017416800526, lng: 55.1549168967729},
        polygonCoords: [],
        icon: MarkPalmBeachTowers,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: '棕榈海滩公寓',
            previews: [
                PalmBeachTowersPreview1,
                PalmBeachTowersPreview2,
                PalmBeachTowersPreview3,
                PalmBeachTowersPreview4,
                PalmBeachTowersPreview5,
                PalmBeachTowersPreview6,
                PalmBeachTowersPreview7,
                PalmBeachTowersPreview8,
                PalmBeachTowersPreview9,
                PalmBeachTowersPreview10,
            ],
            desc: '',
            link: 'https://www.palmbeachtowers.ae',
            video: PalmBeachTowersVideo,
            buttons: [
                {
                    type: "single",
                    data: [
                        {
                            type: 'img',
                            text: "楼书",
                            data: [{
                                file: [
                                    PalmBeachTowersBrochure1,
                                    PalmBeachTowersBrochure2,
                                    PalmBeachTowersBrochure3,
                                    PalmBeachTowersBrochure4,
                                    PalmBeachTowersBrochure5,
                                    PalmBeachTowersBrochure6,
                                    PalmBeachTowersBrochure7,
                                    PalmBeachTowersBrochure8,
                                    PalmBeachTowersBrochure9,
                                    PalmBeachTowersBrochure10,
                                    PalmBeachTowersBrochure11,
                                    PalmBeachTowersBrochure12,
                                    PalmBeachTowersBrochure13,
                                    PalmBeachTowersBrochure14,
                                    PalmBeachTowersBrochure15
                                ]
                            }]
                        }
                    ]
                }
            ]
        }
    },
    {
        id: 'the_world',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.2235063463483, lng: 55.1696737430049},
        polygonCoords: [
            the_world_pol_1,
            the_world_pol_2,
            the_world_pol_3,
            the_world_pol_4,
            the_world_pol_5,
            the_world_pol_6,
            the_world_pol_7,
            the_world_pol_8,
            the_world_pol_9,
            the_world_pol_10,
            the_world_pol_11,
            the_world_pol_12,
            the_world_pol_13,
            the_world_pol_14,
            the_world_pol_15,
            the_world_pol_16,
            the_world_pol_17,
            the_world_pol_18,
            the_world_pol_19,
            the_world_pol_20,
            the_world_pol_21,
            the_world_pol_22,
            the_world_pol_23,
            the_world_pol_24,
            the_world_pol_25,
            the_world_pol_26,
            the_world_pol_27,
            the_world_pol_28,
            the_world_pol_29,
            the_world_pol_30,
            the_world_pol_31,
            the_world_pol_32,
            the_world_pol_33,
            the_world_pol_34,
            the_world_pol_35,
            the_world_pol_36,
            the_world_pol_37,
            the_world_pol_38,
            the_world_pol_39,
            the_world_pol_40,
            the_world_pol_41,
            the_world_pol_42,
            the_world_pol_43,
            the_world_pol_44,
            the_world_pol_45,
            the_world_pol_46,
            the_world_pol_47,
            the_world_pol_48,
            the_world_pol_49,
            the_world_pol_50,
            the_world_pol_51,
            the_world_pol_52,
            the_world_pol_53,
            the_world_pol_54,
            the_world_pol_55,
            the_world_pol_56,
            the_world_pol_57,
            the_world_pol_58,
            the_world_pol_59,
            the_world_pol_60,
            the_world_pol_61,
            the_world_pol_62,
            the_world_pol_63,
            the_world_pol_64,
            the_world_pol_65,
            the_world_pol_66,
            the_world_pol_67,
            the_world_pol_68,
            the_world_pol_69,
            the_world_pol_70,
            the_world_pol_71,
            the_world_pol_72,
            the_world_pol_73,
            the_world_pol_74,
            the_world_pol_75,
            the_world_pol_76,
            the_world_pol_77,
            the_world_pol_78,
            the_world_pol_79,
            the_world_pol_80,
            the_world_pol_81,
            the_world_pol_82,
            the_world_pol_83,
            the_world_pol_84,
            the_world_pol_85,
            the_world_pol_86,
            the_world_pol_87,
            the_world_pol_88,
            the_world_pol_89,
            the_world_pol_90,
            the_world_pol_91,
            the_world_pol_92,
            the_world_pol_93,
            the_world_pol_94,
            the_world_pol_95,
            the_world_pol_96,
            the_world_pol_97,
            the_world_pol_98,
            the_world_pol_99,
            the_world_pol_100,
            the_world_pol_101,
            the_world_pol_102,
            the_world_pol_103,
            the_world_pol_104,
            the_world_pol_105,
            the_world_pol_106,
            the_world_pol_107,
            the_world_pol_108,
            the_world_pol_109,
            the_world_pol_110,
            the_world_pol_111,
            the_world_pol_112,
            the_world_pol_113,
            the_world_pol_114,
            the_world_pol_115,
            the_world_pol_116,
            the_world_pol_117,
            the_world_pol_118,
            the_world_pol_119,
            the_world_pol_120,
            the_world_pol_121,
            the_world_pol_122,
            the_world_pol_123,
            the_world_pol_124,
            the_world_pol_125,
            the_world_pol_126,
            the_world_pol_127,
            the_world_pol_128,
            the_world_pol_129,
            the_world_pol_130,
            the_world_pol_131,
            the_world_pol_132,
            the_world_pol_133,
            the_world_pol_134,
            the_world_pol_135,
            the_world_pol_136,
            the_world_pol_137,
            the_world_pol_138,
            the_world_pol_139,
            the_world_pol_140,
            the_world_pol_141,
            the_world_pol_142,
            the_world_pol_143,
            the_world_pol_144,
            the_world_pol_145,
            the_world_pol_146,
            the_world_pol_147,
            the_world_pol_148,
            the_world_pol_149,
            the_world_pol_150,
            the_world_pol_151,
            the_world_pol_152,
            the_world_pol_153,
            the_world_pol_154,
            the_world_pol_155,
            the_world_pol_156,
            the_world_pol_157,
            the_world_pol_158,
            the_world_pol_159,
            the_world_pol_160,
            the_world_pol_161,
            the_world_pol_162,
            the_world_pol_163,
            the_world_pol_164,
            the_world_pol_165,
            the_world_pol_166,
            the_world_pol_167,
            the_world_pol_168,
            the_world_pol_169,
            the_world_pol_170,
            the_world_pol_171,
            the_world_pol_172,
            the_world_pol_173,
            the_world_pol_174,
            the_world_pol_175,
            the_world_pol_176,
            the_world_pol_177,
            the_world_pol_178,
            the_world_pol_179,
            the_world_pol_180,
            the_world_pol_181,
            the_world_pol_182,
            the_world_pol_183,
            the_world_pol_184,
            the_world_pol_185,
            the_world_pol_186,
            the_world_pol_187,
            the_world_pol_188,
            the_world_pol_189,
            the_world_pol_190,
            the_world_pol_191,
            the_world_pol_192,
            the_world_pol_193,
            the_world_pol_194,
            the_world_pol_195,
            the_world_pol_196,
            the_world_pol_197,
            the_world_pol_198,
            the_world_pol_199,
            the_world_pol_200,
            the_world_pol_201,
            the_world_pol_202,
            the_world_pol_203,
            the_world_pol_204,
        ],
        icon: MarkTheWorld,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'nad_al_sheba_villas',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.1601657220897, lng: 55.3718879480575},
        polygonCoords: [],
        icon: MarkNadAlShebaVillas,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'warsan_village',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.1555223698421, lng: 55.4050612441932},
        polygonCoords: [],
        icon: MarkWarsanVillage,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'international_city',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.1647181646089, lng: 55.4080603547548},
        polygonCoords: [
            international_city_1,
            international_city_2
        ],
        icon: MarkInternationalCity,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'canal_front_residences',
        checkIsInGroup: true,
        groupName: ['meydan', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.185830988435, lng: 55.248199985770604},
        polygonCoords: [canal_front_residences],
        icon: MarkCanalFrontResidences,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null,
        // content: {
            // title: '运河景观公寓',
            // previews: [
            //     CanalFrontResidencesPreview1,
            //     CanalFrontResidencesPreview2,
            //     CanalFrontResidencesPreview3,
            //     CanalFrontResidencesPreview4,
            //     CanalFrontResidencesPreview5,
            // ],
            // desc: '',
            // link: 'https://www.canalfrontresidences.meydan.ae/',
            // video: CanalFrontResidencesVideo,
            // buttons: [
            //     {
            //         type: "double",
            //         data: [
            //             {
            //                 type: 'img',
            //                 text: "楼书",
            //                 data: [{
            //                     file: [
            //                         CanalFrontResidencesBrochure1,
            //                         CanalFrontResidencesBrochure2,
            //                         CanalFrontResidencesBrochure3,
            //                         CanalFrontResidencesBrochure4,
            //                         CanalFrontResidencesBrochure5,
            //                         CanalFrontResidencesBrochure6,
            //                         CanalFrontResidencesBrochure7,
            //                         CanalFrontResidencesBrochure8,
            //                         CanalFrontResidencesBrochure9,
            //                         CanalFrontResidencesBrochure10,
            //                         CanalFrontResidencesBrochure11,
            //                         CanalFrontResidencesBrochure12,
            //                         CanalFrontResidencesBrochure13,
            //                         CanalFrontResidencesBrochure14,
            //                         CanalFrontResidencesBrochure15
            //                     ]
            //                 }]
            //             },
            //             {
            //                 type: 'img',
            //                 text: "楼层规划图",
            //                 data: [{
            //                     file: [
            //                         CanalFrontResidencesFloorPlans1,
            //                         CanalFrontResidencesFloorPlans2,
            //                         CanalFrontResidencesFloorPlans3,
            //                         CanalFrontResidencesFloorPlans4,
            //                         CanalFrontResidencesFloorPlans5,
            //                         CanalFrontResidencesFloorPlans6,
            //                         CanalFrontResidencesFloorPlans7,
            //                         CanalFrontResidencesFloorPlans8,
            //                         CanalFrontResidencesFloorPlans9,
            //                         CanalFrontResidencesFloorPlans10,
            //                         CanalFrontResidencesFloorPlans11,
            //                         CanalFrontResidencesFloorPlans12,
            //                         CanalFrontResidencesFloorPlans13,
            //                         CanalFrontResidencesFloorPlans14,
            //                         CanalFrontResidencesFloorPlans15
            //                     ]
            //                 }]
            //             }
            //         ]
            //     },
            //     {
            //         type: "double",
            //         data: [
            //             {
            //                 type: 'img',
            //                 text: "资料页",
            //                 data: [{
            //                     file: [
            //                         CanalFrontResidencesFactSheet1,
            //                         CanalFrontResidencesFactSheet2,
            //                         CanalFrontResidencesFactSheet3,
            //                         CanalFrontResidencesFactSheet4,
            //                         CanalFrontResidencesFactSheet5
            //                     ]
            //                 }]
            //             },
            //             {
            //                 type: 'img',
            //                 text: "付款计划",
            //                 data: [{file: [CanalFrontResidencesPaymentPlan]}]
            //             },
            //         ]
            //     }
            // ]
        // }
    },
    {
        id: 'district_one',
        checkIsInGroup: true,
        groupName: ['meydan', 'plots'],
        zoomLevel: null,
        coords: {lat: 25.1664659277986, lng: 55.2772011690859},
        polygonCoords: [district_one],
        icon: MarkDistrictOne,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    // {
    //     id: 'district_one_west1',
    //     checkIsInGroup: true,
    //     groupName: ['meydan', 'plots'],
    //     zoomLevel: 12,
    //     coords: {lat: 25.1452064907647, lng: 55.2776460317349},
    //     polygonCoords: null,
    //     icon: MarkDistrictOneWest,
    //     activeIcon: null,
    //     clickable: {
    //         marker: true,
    //         polygon: true
    //     },
    //     content: null
    // },
    {
        id: 'golf_course',
        checkIsInGroup: true,
        groupName: ['meydan', 'villas'],
        zoomLevel: null,
        coords: {lat: 25.166213527711, lng: 55.3134315968028},
        polygonCoords: null,
        icon: MarkGolfCourse,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'polo_residence',
        checkIsInGroup: true,
        groupName: ['meydan', 'villas'],
        zoomLevel: 12,
        coords: {lat: 25.1527592544695, lng: 55.2948257942159},
        polygonCoords: null,
        icon: MarkPoloResidence,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'meydan_hotel',
        checkIsInGroup: true,
        groupName: ['meydan', 'villas'],
        zoomLevel: null,
        coords: {lat: 25.1596989530687, lng: 55.3035742049431},
        polygonCoords: null,
        icon: MarkMeydanHotel,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'meydan_avenue',
        checkIsInGroup: true,
        groupName: ['meydan', 'plots'],
        zoomLevel: 12,
        coords: {lat: 25.1578386088726, lng: 55.2945025159534},
        polygonCoords: null,
        icon: MarkMeydanAvenue,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null,
        // content: {
            // title: '美丹大道',
            // previews: [
            //     MeydanAvenuePreview1,
            //     MeydanAvenuePreview2,
            //     MeydanAvenuePreview3,
            //     MeydanAvenuePreview4,
            //     MeydanAvenuePreview5,
            //     MeydanAvenuePreview6,
            // ],
            // desc: '',
            // link: null,
            // video: null,
            // buttons: [
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 type: 'img',
            //                 text: "地皮A",
            //                 data: [
            //                     {
            //                         title: "地皮设计",
            //                         file: [
            //                             MeydanAvenuePlotAPlotDesign1,
            //                             MeydanAvenuePlotAPlotDesign2,
            //                             MeydanAvenuePlotAPlotDesign3,
            //                             MeydanAvenuePlotAPlotDesign4,
            //                             MeydanAvenuePlotAPlotDesign5,
            //                             MeydanAvenuePlotAPlotDesign6,
            //                             MeydanAvenuePlotAPlotDesign7,
            //                             MeydanAvenuePlotAPlotDesign8,
            //                             MeydanAvenuePlotAPlotDesign9,
            //                             MeydanAvenuePlotAPlotDesign10,
            //                             MeydanAvenuePlotAPlotDesign11,
            //                             MeydanAvenuePlotAPlotDesign12,
            //                             MeydanAvenuePlotAPlotDesign13,
            //                             MeydanAvenuePlotAPlotDesign14,
            //                             MeydanAvenuePlotAPlotDesign15
            //                         ]
            //                     },
            //                     {
            //                         title: "开发控制",
            //                         file: [
            //                             MeydanAvenuePlotADevControl1,
            //                             MeydanAvenuePlotADevControl2,
            //                             MeydanAvenuePlotADevControl3,
            //                             MeydanAvenuePlotADevControl4,
            //                             MeydanAvenuePlotADevControl5,
            //                             MeydanAvenuePlotADevControl6,
            //                             MeydanAvenuePlotADevControl7,
            //                             MeydanAvenuePlotADevControl8,
            //                             MeydanAvenuePlotADevControl9,
            //                             MeydanAvenuePlotADevControl10,
            //                             MeydanAvenuePlotADevControl11,
            //                             MeydanAvenuePlotADevControl12,
            //                             MeydanAvenuePlotADevControl13,
            //                             MeydanAvenuePlotADevControl14,
            //                             MeydanAvenuePlotADevControl15
            //                         ]
            //                     },
            //                     {
            //                         title: "楼书",
            //                         file: [
            //                             MeydanAvenuePlotABrochure1,
            //                             MeydanAvenuePlotABrochure2,
            //                             MeydanAvenuePlotABrochure3,
            //                             MeydanAvenuePlotABrochure4,
            //                             MeydanAvenuePlotABrochure5,
            //                             MeydanAvenuePlotABrochure6,
            //                             MeydanAvenuePlotABrochure7,
            //                             MeydanAvenuePlotABrochure8,
            //                             MeydanAvenuePlotABrochure9,
            //                             MeydanAvenuePlotABrochure10,
            //                             MeydanAvenuePlotABrochure11,
            //                             MeydanAvenuePlotABrochure12,
            //                             MeydanAvenuePlotABrochure13,
            //                             MeydanAvenuePlotABrochure14,
            //                             MeydanAvenuePlotABrochure15
            //                         ]
            //                     },
            //                 ]
            //             }
            //         ]
            //     },
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 type: 'img',
            //                 text: "地皮B",
            //                 data: [
            //                     {
            //                         title: "地皮设计",
            //                         file: [
            //                             MeydanAvenuePlotBPlotDesign1,
            //                             MeydanAvenuePlotBPlotDesign2,
            //                             MeydanAvenuePlotBPlotDesign3,
            //                             MeydanAvenuePlotBPlotDesign4,
            //                             MeydanAvenuePlotBPlotDesign5,
            //                             MeydanAvenuePlotBPlotDesign6,
            //                             MeydanAvenuePlotBPlotDesign7,
            //                             MeydanAvenuePlotBPlotDesign8,
            //                             MeydanAvenuePlotBPlotDesign9,
            //                             MeydanAvenuePlotBPlotDesign10,
            //                             MeydanAvenuePlotBPlotDesign11,
            //                             MeydanAvenuePlotBPlotDesign12,
            //                             MeydanAvenuePlotBPlotDesign13,
            //                             MeydanAvenuePlotBPlotDesign14,
            //                             MeydanAvenuePlotBPlotDesign15
            //                         ]
            //                     },
            //                     {
            //                         title: "开发控制",
            //                         file: [
            //                             MeydanAvenuePlotBDevControl1,
            //                             MeydanAvenuePlotBDevControl2,
            //                             MeydanAvenuePlotBDevControl3,
            //                             MeydanAvenuePlotBDevControl4,
            //                             MeydanAvenuePlotBDevControl5,
            //                             MeydanAvenuePlotBDevControl6,
            //                             MeydanAvenuePlotBDevControl7,
            //                             MeydanAvenuePlotBDevControl8,
            //                             MeydanAvenuePlotBDevControl9,
            //                             MeydanAvenuePlotBDevControl10,
            //                             MeydanAvenuePlotBDevControl11,
            //                             MeydanAvenuePlotBDevControl12,
            //                             MeydanAvenuePlotBDevControl13,
            //                             MeydanAvenuePlotBDevControl14,
            //                             MeydanAvenuePlotBDevControl15
            //                         ]
            //                     },
            //                     {
            //                         title: "楼书",
            //                         file: [
            //                             MeydanAvenuePlotBBrochure1,
            //                             MeydanAvenuePlotBBrochure2,
            //                             MeydanAvenuePlotBBrochure3,
            //                             MeydanAvenuePlotBBrochure4,
            //                             MeydanAvenuePlotBBrochure5,
            //                             MeydanAvenuePlotBBrochure6,
            //                             MeydanAvenuePlotBBrochure7,
            //                             MeydanAvenuePlotBBrochure8,
            //                             MeydanAvenuePlotBBrochure9,
            //                             MeydanAvenuePlotBBrochure10,
            //                             MeydanAvenuePlotBBrochure11,
            //                             MeydanAvenuePlotBBrochure12,
            //                             MeydanAvenuePlotBBrochure13,
            //                             MeydanAvenuePlotBBrochure14,
            //                             MeydanAvenuePlotBBrochure15
            //                         ]
            //                     },
            //                 ]
            //             }
            //         ]
            //     },
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 type: 'img',
            //                 text: "地皮C",
            //                 data: [
            //                     {
            //                         title: "地皮设计",
            //                         file: [
            //                             MeydanAvenuePlotCPlotDesign1,
            //                             MeydanAvenuePlotCPlotDesign2,
            //                             MeydanAvenuePlotCPlotDesign3,
            //                             MeydanAvenuePlotCPlotDesign4,
            //                             MeydanAvenuePlotCPlotDesign5,
            //                             MeydanAvenuePlotCPlotDesign6,
            //                             MeydanAvenuePlotCPlotDesign7,
            //                             MeydanAvenuePlotCPlotDesign8,
            //                             MeydanAvenuePlotCPlotDesign9,
            //                             MeydanAvenuePlotCPlotDesign10,
            //                             MeydanAvenuePlotCPlotDesign11,
            //                             MeydanAvenuePlotCPlotDesign12,
            //                             MeydanAvenuePlotCPlotDesign13,
            //                             MeydanAvenuePlotCPlotDesign14,
            //                             MeydanAvenuePlotCPlotDesign15
            //                         ]
            //                     },
            //                     {
            //                         title: "开发控制",
            //                         file: [
            //                             MeydanAvenuePlotCDevControl1,
            //                             MeydanAvenuePlotCDevControl2,
            //                             MeydanAvenuePlotCDevControl3,
            //                             MeydanAvenuePlotCDevControl4,
            //                             MeydanAvenuePlotCDevControl5,
            //                             MeydanAvenuePlotCDevControl6,
            //                             MeydanAvenuePlotCDevControl7,
            //                             MeydanAvenuePlotCDevControl8,
            //                             MeydanAvenuePlotCDevControl9,
            //                             MeydanAvenuePlotCDevControl10,
            //                             MeydanAvenuePlotCDevControl11,
            //                             MeydanAvenuePlotCDevControl12,
            //                             MeydanAvenuePlotCDevControl13,
            //                             MeydanAvenuePlotCDevControl14,
            //                             MeydanAvenuePlotCDevControl15
            //                         ]
            //                     },
            //                     {
            //                         title: "楼书",
            //                         file: [
            //                             MeydanAvenuePlotCBrochure1,
            //                             MeydanAvenuePlotCBrochure2,
            //                             MeydanAvenuePlotCBrochure3,
            //                             MeydanAvenuePlotCBrochure4,
            //                             MeydanAvenuePlotCBrochure5,
            //                             MeydanAvenuePlotCBrochure6,
            //                             MeydanAvenuePlotCBrochure7,
            //                             MeydanAvenuePlotCBrochure8,
            //                             MeydanAvenuePlotCBrochure9,
            //                             MeydanAvenuePlotCBrochure10,
            //                             MeydanAvenuePlotCBrochure11,
            //                             MeydanAvenuePlotCBrochure12,
            //                             MeydanAvenuePlotCBrochure13,
            //                             MeydanAvenuePlotCBrochure14,
            //                             MeydanAvenuePlotCBrochure15
            //                         ]
            //                     },
            //                 ]
            //             }
            //         ]
            //     }
            // ]
        // }
    },
    {
        id: 'district_11',
        checkIsInGroup: true,
        groupName: ['meydan', 'villas'],
        zoomLevel: null,
        coords: {lat: 25.125008726617, lng: 55.3520318687308},
        polygonCoords: [district_11],
        icon: MarkDistrict11,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null,
        // content: {
            // title: '美丹11区',
            // previews: [
            //     District11Preview1,
            //     District11Preview2,
            //     District11Preview3,
            //     District11Preview4,
            //     District11Preview5,
            //     District11Preview6,
            // ],
            // desc: '',
            // link: null,
            // video: null,
            // buttons: [
            //     {
            //         type: "double",
            //         data: [
            //             {
            //                 type: 'img',
            //                 text: "楼书",
            //                 data: [{
            //                     file: [
            //                         District11Brochure1,
            //                         District11Brochure2,
            //                         District11Brochure3,
            //                         District11Brochure4,
            //                         District11Brochure5,
            //                         District11Brochure6,
            //                         District11Brochure7,
            //                         District11Brochure8,
            //                         District11Brochure9,
            //                         District11Brochure10,
            //                         District11Brochure11,
            //                         District11Brochure12,
            //                         District11Brochure13,
            //                         District11Brochure14,
            //                         District11Brochure15
            //                     ]
            //                 }]
            //             },
            //             {
            //                 type: 'img',
            //                 text: "楼层规划图",
            //                 data: [{
            //                     file: [
            //                         District11FloorPlans1,
            //                         District11FloorPlans2,
            //                         District11FloorPlans3,
            //                         District11FloorPlans4,
            //                         District11FloorPlans5,
            //                         District11FloorPlans6,
            //                         District11FloorPlans7,
            //                         District11FloorPlans8,
            //                         District11FloorPlans9,
            //                         District11FloorPlans10,
            //                         District11FloorPlans11
            //                     ]
            //                 }]
            //             }
            //         ]
            //     },
            //     {
            //         type: "single",
            //         data: [
            //             {
            //                 type: 'img',
            //                 text: "资料页",
            //                 data: [{
            //                     file: [
            //                         District11Factsheet1,
            //                         District11Factsheet2,
            //                         District11Factsheet3,
            //                         District11Factsheet4
            //                     ]
            //                 }]
            //             }
            //         ]
            //     }
            // ]
        // }
    },
    {
        id: 'naya_residence',
        checkIsInGroup: true,
        groupName: ['meydan', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.16281754, lng: 55.28744163},
        polygonCoords: [],
        icon: NayaResidenceMark,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: '娜娅公寓',
            previews: [
                NayaResidencePreview1,
                NayaResidencePreview2,
                NayaResidencePreview3,
                NayaResidencePreview4,
                NayaResidencePreview5,
                NayaResidencePreview6,
                NayaResidencePreview7,
                NayaResidencePreview8,
                NayaResidencePreview9,
                NayaResidencePreview10,
            ],
            desc: '',
            link: '',
            video: NayaResidenceVideo,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "楼层规划图",
                            data: [
                                {
                                    title: "Naya 1 Floor",
                                    file: [
                                        NayaResidenceFloorPlansNaya1_1,
                                        NayaResidenceFloorPlansNaya1_2,
                                        NayaResidenceFloorPlansNaya1_3,
                                        NayaResidenceFloorPlansNaya1_4,
                                        NayaResidenceFloorPlansNaya1_5,
                                        NayaResidenceFloorPlansNaya1_6,
                                        NayaResidenceFloorPlansNaya1_7,
                                        NayaResidenceFloorPlansNaya1_8,
                                        NayaResidenceFloorPlansNaya1_9,
                                        NayaResidenceFloorPlansNaya1_10,
                                        NayaResidenceFloorPlansNaya1_11,
                                        NayaResidenceFloorPlansNaya1_12,
                                        NayaResidenceFloorPlansNaya1_13,
                                        NayaResidenceFloorPlansNaya1_14,
                                        NayaResidenceFloorPlansNaya1_15
                                    ]
                                },
                                {
                                    title: "Naya 2 Floor",
                                    file: [
                                        NayaResidenceFloorPlansNaya2_1,
                                        NayaResidenceFloorPlansNaya2_2,
                                        NayaResidenceFloorPlansNaya2_3,
                                        NayaResidenceFloorPlansNaya2_4,
                                        NayaResidenceFloorPlansNaya2_5,
                                        NayaResidenceFloorPlansNaya2_6,
                                        NayaResidenceFloorPlansNaya2_7,
                                        NayaResidenceFloorPlansNaya2_8,
                                        NayaResidenceFloorPlansNaya2_9,
                                        NayaResidenceFloorPlansNaya2_10,
                                        NayaResidenceFloorPlansNaya2_11,
                                        NayaResidenceFloorPlansNaya2_12,
                                        NayaResidenceFloorPlansNaya2_13,
                                        NayaResidenceFloorPlansNaya2_14,
                                        NayaResidenceFloorPlansNaya2_15
                                    ]
                                },
                                {
                                    title: "Naya 3 Floor",
                                    file: [
                                        NayaResidenceFloorPlansNaya3_1,
                                        NayaResidenceFloorPlansNaya3_2,
                                        NayaResidenceFloorPlansNaya3_3,
                                        NayaResidenceFloorPlansNaya3_4,
                                        NayaResidenceFloorPlansNaya3_5,
                                        NayaResidenceFloorPlansNaya3_6,
                                        NayaResidenceFloorPlansNaya3_7,
                                        NayaResidenceFloorPlansNaya3_8,
                                        NayaResidenceFloorPlansNaya3_9,
                                        NayaResidenceFloorPlansNaya3_10,
                                        NayaResidenceFloorPlansNaya3_11,
                                        NayaResidenceFloorPlansNaya3_12,
                                        NayaResidenceFloorPlansNaya3_13,
                                        NayaResidenceFloorPlansNaya3_14,
                                        NayaResidenceFloorPlansNaya3_15
                                    ]
                                },
                                {
                                    title: "Villas",
                                    file: [
                                        NayaResidenceFloorPlansNayaLagoonVillas1,
                                        NayaResidenceFloorPlansNayaLagoonVillas2,
                                        NayaResidenceFloorPlansNayaLagoonVillas3,
                                        NayaResidenceFloorPlansNayaLagoonVillas4,
                                        NayaResidenceFloorPlansNayaLagoonVillas5,
                                        NayaResidenceFloorPlansNayaLagoonVillas6,
                                        NayaResidenceFloorPlansNayaLagoonVillas7,
                                        NayaResidenceFloorPlansNayaLagoonVillas8,
                                        NayaResidenceFloorPlansNayaLagoonVillas9,
                                        NayaResidenceFloorPlansNayaLagoonVillas10,
                                        NayaResidenceFloorPlansNayaLagoonVillas11
                                    ]
                                },
                            ]
                        },
                        {
                            type: 'img',
                            text: "楼书",
                            data: [{
                                file: [
                                    NayaResidenceBrochure1,
                                    NayaResidenceBrochure2,
                                    NayaResidenceBrochure3,
                                    NayaResidenceBrochure4,
                                    NayaResidenceBrochure5,
                                    NayaResidenceBrochure6,
                                    NayaResidenceBrochure7,
                                    NayaResidenceBrochure8,
                                    NayaResidenceBrochure9,
                                    NayaResidenceBrochure10,
                                    NayaResidenceBrochure11,
                                    NayaResidenceBrochure12,
                                    NayaResidenceBrochure13,
                                    NayaResidenceBrochure14,
                                    NayaResidenceBrochure15,
                                ]
                            }]
                        }
                    ]
                },
            ]
        }
    },
    {
        id: 'dxb_airport',
        checkIsInGroup: true,
        groupName: ['landmarks'],
        zoomLevel: null,
        coords: {lat: 25.253142380921556, lng: 55.3656575614154},
        polygonCoords: null,
        icon: MarkAirport,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false
        },
        content: null
    },
    {
        id: 'dubai_canal',
        checkIsInGroup: true,
        groupName: ['landmarks'],
        zoomLevel: 12,
        coords: {lat: 25.194975903997243, lng: 55.238651929645435},
        polygonCoords: null,
        icon: MarkDubaiCanal,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false
        },
        content: null
    },
    {
        id: 'jumeirah_beach',
        checkIsInGroup: true,
        groupName: ['landmarks'],
        zoomLevel: null,
        coords: {lat: 25.19391473532379, lng: 55.231646559007714},
        polygonCoords: null,
        icon: MarkJumeirahBeach,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false
        },
        content: null
    },
    {
        id: 'nakheel_marinas',
        checkIsInGroup: true,
        groupName: ['landmarks'],
        zoomLevel: null,
        coords: {lat: 25.29042993499695, lng: 55.296927953490645},
        polygonCoords: null,
        icon: MarkNakheelMarinas,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false
        },
        content: null
    },
    {
        id: 'the_view_at_the_palm',
        checkIsInGroup: true,
        groupName: ['landmarks'],
        zoomLevel: 11,
        coords: {lat: 25.112681170745817, lng: 55.13878120002334},
        polygonCoords: null,
        icon: MarkTheViewAtThePalm,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false
        },
        content: null
    },
    {
        id: 'palm_monorail',
        checkIsInGroup: true,
        groupName: ['landmarks'],
        zoomLevel: 12.5,
        coords: {lat: 25.100156205311755, lng: 55.15649614474076},
        polygonCoords: null,
        icon: MarkPalmMonorail,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false
        },
        content: null
    },
    {
        id: 'nakheel_sales',
        checkIsInGroup: true,
        groupName: ['landmarks'],
        zoomLevel: null,
        coords: {lat: 25.103536132427655, lng: 55.15330807676427},
        polygonCoords: null,
        icon: MarkNakheelSales,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false
        },
        content: null
    },
    {
        id: 'dwc_airport',
        checkIsInGroup: true,
        groupName: ['landmarks'],
        zoomLevel: null,
        coords: {lat: 24.885818090910437, lng: 55.158727077847054},
        polygonCoords: null,
        icon: MarkAirportDWC,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false
        },
        content: null
    },

]


export const polylines = [
    {
        id: 'bus_station_1',
        path: bus_road_1,
        stops: bus_road_1_stops,
        icon: MarkBus1,
        color: "#EC5650",
    },
    {
        id: 'bus_station_1_2',
        path: bus_road_1_2,
        stops: [],
        icon: MarkBus1,
        color: "#EC5650",
    },
    {
        id: 'bus_station_2',
        path: bus_road_2,
        stops: bus_road_2_stops,
        icon: MarkBus2,
        color: "#49CD57",
    },
    {
        id: 'bus_station_3',
        path: bus_road_3,
        stops: bus_road_3_stops,
        icon: MarkBus3,
        color: "#F26E24",
    },
]
