export const dropdownsData = (
    checkboxFiltersRadio,
    setCheckboxFiltersRadio
) => {
    return ([
    {
        type: 'checkbox',
        title: '项目开发',
        id: 'developments',
        elements: [
            {
                text: '纳西尔',
                id: 'nakheel'
            },
            {
                text: '美丹',
                id: 'meydan'
            }
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    },
    {
        type: 'checkbox',
        title: '项目类型',
        id: 'project_type',
        elements: [
            {
                text: '别墅',
                id: 'villas'
            },
            {
                text: '公寓',
                id: 'apartments'
            },
            {
                text: '地皮',
                id: 'plots'
            },
            {
                text: '公寓 ＆ 地皮',
                id: 'apartments_villas'
            },
            {
                text: '地皮＆别墅＆公寓',
                id: 'plots_villas_apartments'
            },
            {
                text: '展示全部',
                id: '_all_project_type'
            },
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    },
    {
        type: 'checkbox',
        title: '基础设施',
        id: 'infrastructure',
        elements: [
            {
                text: '地标',
                id: 'landmarks'
            },
            {
                text: '交通 (地铁)',
                id: 'transport'
            },
            {
                text: '教育',
                id: 'education'
            },
            {
                text: '健康',
                id: 'health'
            },
            {
                text: '购物',
                id: 'retail_or_shopping'
            }
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    }
    ])
}